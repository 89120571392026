import React, { useState } from "react";

import TextField from "@material-ui/core/TextField";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

// styles
import useStyles from "../../styles";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";

export default function ContactDetails(props) {
  var classes = useStyles();
  var theme = useTheme();

  return (
    <form
      className={classes.root + " " + classes.subformsc}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={2}>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
           style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
            className={classes.textfieldvaluec}
            // required
            id="first_name"
            label="FIRST NAME"
            type="text"
            placeholder="Johnatan"
            autoComplete="first-name"
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
           style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
            className={classes.textfieldvaluec}
            // required
            id="last_name"
            label="LAST NAME"
            type="text"
            placeholder="Doe"
            autoComplete="last-name"
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
           style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
            className={classes.textfieldvaluec}
            // required
            id="legal_name"
            label="LEGAL NAME OF CUSTOMER"
            type="text"
            placeholder="johnatan Doe"
            autoComplete="legal-name"
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
           style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
            className={classes.textfieldvaluec}
            // required
            id="swift"
            label="SWIFT"
            type="text"
            placeholder="AGRIFRPP"
            autoComplete="swift"
          />
        </Grid>

        <Grid
          item
          lg={9}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
           style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
            // style={ window.innerWidth > 700 ? { width: "100%", lineHeight: 20 } : { width: "180", lineHeight: 20 }}
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
            className={classes.textfieldvaluec}
            // required
            id="traddingname"
            label="TRADING NAME(S) USED ( IF OTHER THAN LEGAL NAME)"
            type="text"
            placeholder="John"
            autoComplete="traddingname"
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
           style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
          // style={{width: 180}}
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
            className={classes.textfieldvaluec}
            // required
            id="establishment-date"
            label="DATE OF INCORPORATION / ESTABLISHMENT"
            type="date"
            value="date"
            autoComplete="establishment-date"
          />
        </Grid>

        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
           style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
            className={classes.textfieldvaluec}
            // required
            id="business_address"
            label="BUSINESS ADDRESS"
            type="text"
            placeholder="202,Atulya IT Park"
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
           style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
            className={classes.textfieldvaluec}
            // required
            id="postal-code"
            label="POSTAL CODE"
            type="text"
            placeholder="452014"
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
           style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
            className={classes.textfieldvaluec}
            // required
            id="location"
            label="LOCATION"
            type="text"
            placeholder="Indore"
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
           style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
            className={classes.textfieldvaluec}
            // required
            id="country"
            label="COUNTRY"
            type="text"
            placeholder="India"
          />
        </Grid>

        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
           style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
          stle={{padding: 10, }}
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              // shrink: true,
              className: classes.matlabelc + " " + classes.nonshrinklevalc,
            }}
            className={classes.textfieldvaluec}
            // required
            id="otheraddress"
            label="LEGAL ADDRESS OTHER THEN BUSINESS"
            type="text"
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
           style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              // shrink: true,
              className: classes.matlabelc + " " + classes.nonshrinklevalc,
            }}
            className={classes.textfieldvaluec}
            // required
            id="ootherpostalcode"
            label="POSTAL CODE"
            type="text"
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
           style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              // shrink: true,
              className: classes.matlabelc + " " + classes.nonshrinklevalc,
            }}
            className={classes.textfieldvaluec}
            // required
            id="ootherlocation"
            label="LOCATON"
            type="text"
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
           style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              // shrink: true,
              className: classes.matlabelc + " " + classes.nonshrinklevalc,
            }}
            className={classes.textfieldvaluec}
            // required
            id="oothercountry"
            label="COUNTRY"
            type="text"
          />
        </Grid>

        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
           style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
            className={classes.textfieldvaluec}
            // required
            id="activities"
            label="ACTIVITIES"
            type="text"
            placeholder="Information Technology"
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
           style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
            className={classes.textfieldvaluec}
            // required
            id="website"
            label="WEBSITE"
            type="text"
            placeholder="www.questglt.com"
          />
        </Grid>
      </Grid>
    </form>
  );
}
