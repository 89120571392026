import React, { useState } from "react";

import { Close as CloseIcon } from "@material-ui/icons";
import { ToastContainer, toast } from "react-toastify";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import classnames from "classnames";
import "./Withdraw.scss";
import WithdrawTable from "../dashboard/components/WithdrawTable/WithdrawTable";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  NativeSelect,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import search from "../../../src/assets/images/search.png";

// styles
import "react-toastify/dist/ReactToastify.css";
import useStyles from "./styles";
import mock from "../dashboard/mock";
import { QRCode } from "react-qr-svg";
import Paper from "@material-ui/core/Paper";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Notification from "../../components/Deposit/Deposit";
import { Typography, Button } from "../../components/Wrappers/Wrappers";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CanvasJSReact from "../../assets/canvasjs.react";
import logo from "../../assets/images/bitcoin.png";
import logo1 from "../../assets/images/Group 56.png";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const positions = [
  toast.POSITION.TOP_LEFT,
  toast.POSITION.TOP_CENTER,
  toast.POSITION.TOP_RIGHT,
  toast.POSITION.BOTTOM_LEFT,
  toast.POSITION.BOTTOM_CENTER,
  toast.POSITION.BOTTOM_RIGHT,
];
const useStylesModal = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    borderRadius: 10,
  },
  paper: {
    backgroundColor: "white",
    // border: "20px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const recentTransaction = [
  {
    id: 1,
    btc: "BTC",
    bank: "Using - Bank ****** 5263",
    NValue: "-0.000242 BTC",
    PValue: "-0.125 USD",
  },
  {
    id: 2,
    btc: "BTC",
    bank: "Using - Bank ****** 5263",
    NValue: "-0.000242 BTC",
    PValue: "-0.125 USD",
  },
  {
    id: 3,
    btc: "BTC",
    bank: "Using - Bank ****** 5263",
    NValue: "-0.000242 BTC",
    PValue: "-0.125 USD",
  },
  {
    id: 4,
    btc: "BTC",
    bank: "Using - Bank ****** 5263",
    NValue: "-0.000242 BTC",
    PValue: "-0.125 USD",
  },
  {
    id: 5,
    btc: "BTC",
    bank: "Using - Bank ****** 5263",
    NValue: "-0.000242 BTC",
    PValue: "-0.125 USD",
  },
  {
    id: 6,
    btc: "BTC",
    bank: "Using - Bank ****** 5263",
    NValue: "-0.000242 BTC",
    PValue: "-0.125 USD",
  },
  {
    id: 6,
    btc: "BTC",
    bank: "Using - Bank ****** 5263",
    NValue: "-0.000242 BTC",
    PValue: "-0.125 USD",
  },
];

export default function NotificationsPage(props) {
  var classes = useStyles();
  const classesModal = useStylesModal();
  const [open, setOpen] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  function wallet() {
    console.log("props --- orders", props.history);
    props.history.push("/app/wallet");
    window.location.reload();

  }

  function orders() {
    console.log("props --- orders", props.history);
    props.history.push("/app/tables");

    window.location.reload();

  }

  function notifications() {
    console.log("props --- Dashboard", props.history);
    props.history.push("/app/notifications");
    window.location.reload();

  }

  // local
  var [notificationsPosition, setNotificationPosition] = useState(2);
  var [errorToastId, setErrorToastId] = useState(null);
  function dashboard() {
    props.history.push("/app/dashboard");
  }
  const options = {
    // exportEnabled: true,
    // animationEnabled: true,

    data: [
      {
        type: "pie",
        startAngle: 75,
        toolTipContent: "<b>{label}</b>: {y}%",
        // showInLegend: "true",
        legendText: "{label}",
        indexLabelFontSize: 16,
        indexLabel: "{label} ({y}%)",
        dataPoints: [
          { y: 60, label: "BTC" },
          { y: 30, label: "USD" },
          { y: 4, label: "Others" },
          { y: 6, label: "XRP" },
        ],
      },
    ],
  };
  return (
    <div className="headerContainer">
      {/* <PageTitle title="Notifications" /> */}
      <Grid container spacing={4}>
        <ToastContainer
          className={classes.toastsContainer}
          closeButton={
            <CloseButton className={classes.notificationCloseButton} />
          }
          closeOnClick={false}
          progressClassName={classes.notificationProgress}
        />
        <Grid item xs={12} md={6} lg={3} style={{ marginTop: 20 }}>
          <Widget
            // title="RECENT LOGINS"
            disableWidgetMenu
          >
            <div
            className="LRpaddingM"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <p
                className="MLR"
                style={{
                  fontSize: 17.2,
                  fontWeight: "bolder",
                  color: "#1D275A",
                  marginTop: -10,
                }}
              >
                TOTAL BALANCE
              </p>
            </div>
            <div style={{ marginTop: -8 }}>
              <hr
                className="borderHR"
                style={{ width: "180%", marginLeft: -30, color: "#F5F5F5" }}
              />
            </div>
            <div style={{ marginBottom: -10 }}>
              <p className="MLR LRpaddingMB" style={{ fontSize: 20, color: "black" }}>
                0.000000 BTC
              </p>
            </div>
          </Widget>
        </Grid>
        <Grid item xs={12} md={6} lg={3} className="marginTD">
          <Widget
            // title="RECENT LOGINS"
            disableWidgetMenu
          >
            <div
            className="LRpaddingM"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <p
                className="MLR"
                style={{
                  fontSize: 17.2,
                  fontWeight: "bolder",
                  color: "#1D275A",
                  marginTop: -10,
                }}
              >
                IN ORDER
              </p>
            </div>
            <div style={{ marginTop: -8 }}>
              <hr
                className="borderHR"
                style={{ width: "180%", marginLeft: -30, color: "#F5F5F5" }}
              />
            </div>
            <div style={{ marginBottom: -10 }}>
              <p className="MLR LRpaddingMB" style={{ fontSize: 20, color: "black" }}>
                0.000000 BTC
              </p>
            </div>
          </Widget>
        </Grid>{" "}
        <Grid item xs={12} md={6} lg={3} className="marginTD">
          <Widget
            // title="RECENT LOGINS"
            disableWidgetMenu
          >
            <div
            className="LRpaddingME"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <p
                className="MLRM "
                style={{
                  fontSize: 17.2,
                  fontWeight: "bolder",
                  color: "#1D275A",
                  marginTop: -10,
                }}
              >
                AVAILABLE BALANCE
              </p>
            </div>
            <div style={{ marginTop: -8 }}>
              <hr
                className="borderHR"
                style={{ width: "180%", marginLeft: -30, color: "#F5F5F5" }}
              />
            </div>
            <div style={{ marginBottom: -10 }}>
              <p className="MLR LRpaddingMB" style={{ fontSize: 20, color: "black" }}>
                0.000000 BTC
              </p>
            </div>
          </Widget>
        </Grid>
        <Grid item xs={12} md={6} lg={3} className="marginTD">
          <Widget
            // title="RECENT AUTHORISED DEVICES"
            disableWidgetMenu
          >
            <div
            className="LRpaddingM"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ flexDirection: "row", display: "flex" }}>
                <p
                  className="MLR"
                  style={{
                    fontSize: 17.2,
                    fontWeight: "bolder",
                    color: "#1D275A",
                    marginTop: -10,
                  }}
                >
                  USE EQUIVALENT
                </p>
              </div>
            </div>
            <div style={{ marginTop: -8 }}>
              <hr
                className="borderHR"
                style={{ width: "180%", marginLeft: -30, color: "#F5F5F5" }}
              />
            </div>
            <div style={{ marginBottom: -10 }}>
              <p className="MLR LRpaddingMB" style={{ fontSize: 20, color: "black" }}>
                0.000000 BTC
              </p>
            </div>
          </Widget>
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        <ToastContainer
          className={classes.toastsContainer}
          closeButton={
            <CloseButton className={classes.notificationCloseButton} />
          }
          closeOnClick={false}
          progressClassName={classes.notificationProgress}
        />

        <Grid item xs={12} md={12} lg={12} className="marginTD">
          <Widget
            // title="RECENT LOGINS"
            disableWidgetMenu
          >
            <div
            className="LRpaddingMMob"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: -10,
              }}
            >
              <p
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#1D275A",
                  marginTop: -8,
                }}
              >
                WITHDRAW
              </p>
            </div>
            <div style={{ marginTop: -10 }}>
              <hr
                className="borderHR"
                style={{ width: "120%", marginLeft: -30, color: "#F5F5F5" }}
              />
            </div>
            <div className="withDrawCSS">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <div>
                    <Grid item>
                      <FormControl className={classes.formControl}>
                        <Select
                          placeholder="Select ID Types"
                          style={{
                            width: 275,
                            backgroundColor: "#F0F1F5",
                            borderRadius: 8,
                            height: 40,
                          }}
                          displayEmpty
                          className={classes.selectEmpty}
                          labelId="label"
                          id="select"
                        >
                          <MenuItem value="10">None</MenuItem>
                          <MenuItem value="20">BCH - BCH</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <div
                      style={{
                        marginTop: 20,
                      }}
                    >
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                          height: 26,
                          width: 250,
                        }}
                      >
                        <p style={{ fontSize: 12.5, color: "#1D275A" }}>
                          Total Balance
                        </p>
                        <p className="totalCSS">:</p>
                        <p style={{ fontSize: 12.5, color: "#1D275A" }}>
                          0.000000 BCH
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                          height: 26,
                          width: 250,
                        }}
                      >
                        <p style={{ fontSize: 12.5, color: "#1D275A" }}>
                          In Order{" "}
                        </p>
                        <p className="inOrderCSS">:</p>
                        <p style={{ fontSize: 12.5, color: "#1D275A" }}>
                          0.000000 BCH
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          width: 250,
                          justifyContent: "space-between",
                          height: 26,
                        }}
                      >
                        <p style={{ fontSize: 12.5, color: "#1D275A" }}>
                          Available Balance
                        </p>
                        <p className="availableCSS">:</p>
                        <p style={{ fontSize: 12.5, color: "#1D275A" }}>
                          0.000000 BCH
                        </p>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <div className="margin20TD">
                    {" "}
                    <Grid
                      item
                      lg={6}
                      md={4}
                      sm={6}
                      xs={12}
                      className={
                        classes.center_elements + " " + classes.griditemc
                      }
                    >
                      <TextField
                        InputProps={{
                          className:
                            classes.inputc + " " + classes.notchedOutline,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          className: classes.matlabelc,
                        }}
                        className={classes.textfieldvaluec}
                        placeholder="RECIPIENT'S ADDRESS"
                        // required
                        id="recipient_address"
                        // label="COMPANY ADDRESS"
                        type="text"
                        autoComplete="recipient_address"
                      />
                    </Grid>{" "}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <div className="margin20TD MtR">
                    {" "}
                    <div className="alignAva">
                      <Grid
                        item
                        lg={6}
                        md={4}
                        sm={6}
                        xs={12}
                        className={
                          classes.center_elements + " " + classes.griditemc
                        }
                      >
                        <span>
                          <TextField
                            InputProps={{
                              className:
                                classes.inputc + " " + classes.notchedOutline,
                            }}
                            InputLabelProps={{
                              shrink: true,
                              className: classes.matlabelc,
                            }}
                            className={classes.textfieldvaluec}
                            placeholder="ENTER AMOUNT"
                            // required
                            id="enter_amount"
                            label="ENTER AMOUNT"
                            type="text"
                            autoComplete="enter_amount"
                          />
                        </span>
                      </Grid>
                      <div className="bch ">
                        <p>BCH</p>
                      </div>
                    </div>
                    <div className="FDalign">
                      <p
                        style={{
                          fontSize: 12,
                          color: "#1D275A",
                          fontWeight: "bold",
                        }}
                      >
                        Available : 0.0000000 BCH
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>

            <span>
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <p style={{ fontSize: 12.5, color: "#1D275A" }}>
                  Transaction Fee : 0.00010000 BCH
                </p>
                <div
                  style={{
                    marginTop: 10,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "34%",
                  }}
                >
                  <Button
                    variant="contained"
                    colortheme="primary"
                    className={classnames(classes.notificationCallButton)}
                    style={{
                      backgroundColor: "#ED1B24",
                      color: "white",
                      fontSize: 16,
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    Withdraw
                  </Button>
                </div>
              </div>
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p
               className="redMessage"
              >
                Do not withdraw directly to a crowdfund or ICO address, as your
                account will not be credited with token from such sales.
              </p>
            </div>
          </Widget>
        </Grid>
      </Grid>
      <Grid>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Widget>
            <div
            className="LRpaddingM"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <p
                style={{
                  padding: 12,
                  fontSize: 18,
                  color: "#1D275A",
                  fontWeight: "bold",
                  marginTop: -20,
                }}
              >
                RECENT TRANSACTIONS
              </p>

              <span className="displayDesktopBlock displayNotebookBlock displayTbletBlock displayMobileNone">
                <div
                  style={{
                    flexDirection: "row-reverse",
                    display: "flex",
                    marginTop: -20,
                  }}
                >
                  <TextField
                    style={{ padding: 2 }}
                    id="outlined-basic"
                    label="Search"
                    variant="outlined"
                  />

                  <div style={{ paddingRight: 14, position: "absolute" }}>
                    <img
                      style={{
                        height: 30,
                        width: 30,

                        padding: 4,
                        marginTop: 10,
                      }}
                      src={search}
                      alt="logo"
                    />
                  </div>
                </div>
              </span>
            </div>
            <span className="displayMobileBlock displayTbletNone displayNotebookNone displayDesktopNone">
              <div
                style={{
                  marginTop: -30,
                  flexDirection: "row-reverse",
                  display: "flex",
                }}
              >
                <TextField
                  style={{ padding: 2, width: 200 }}
                  id="outlined-basic"
                  label="Search"
                  variant="outlined"
                />
                <div style={{ paddingRight: 14, position: "absolute" }}>
                  <img
                    style={{
                      height: 30,
                      width: 30,

                      padding: 4,
                      marginTop: 10,
                    }}
                    src={search}
                    alt="logo"
                  />
                </div>
              </div>
            </span>

            <div className="RLine">
              <hr className="borderHR RLine" />
            </div>
            <div className="tableMargin MBL">
              {recentTransaction.map((recent) => (
                <Grid container spacing={3} className="MB">
                  <Grid item xs={4} sm={4} md={1} lg={1} xl={1}>
                    <div style={{ marginTop: 10 }}>
                      <img
                        style={{
                          height: 30,
                          width: 30,
                          marginRight: 5,
                          marginTop: -3,
                        }}
                        src={logo1}
                        alt="logo"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={4} md={1} lg={1} xl={1}>
                    <div style={{ marginTop: 8 }}>
                      <Button
                        size="small"
                        className="px-2"
                        variant="contained"
                        style={{
                          backgroundColor: "#E33C28",
                          fontWeight: "bold",
                          width: 60,
                          color: "white",
                        }}
                      >
                        {"SOLD"}
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={4} md={2} lg={2} xl={2} className="MLF">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ marginTop: 10 }}>
                        <img
                          style={{
                            height: 30,
                            width: 30,
                            marginRight: 5,
                            marginTop: -3,
                          }}
                          src={logo}
                          alt="logo"
                        />
                      </div>

                      <p className="tableCSSTxt">{recent.btc}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <p className="tableCSSTxt">{recent.bank}</p>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={3}
                    className="nMLF"
                  >
                    <div>
                      <p className="tableCSSTxt">{recent.NValue}</p>
                    </div>
                  </Grid>{" "}
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={1}
                    lg={1}
                    xl={1}
                    className="nMLF"
                  >
                    <div className="endC">
                      <p className="tableCSSTxt">{recent.PValue}</p>
                    </div>
                  </Grid>
                </Grid>
              ))}
            </div>
          </Widget>
        </Grid>
      </Grid>
      <div style={{ marginTop: 30, marginBottom: 10 }}>
        <p>Copyright 2020 BIThashEX | All Rights Reserved</p>
      </div>
    </div>
  );

  // #############################################################
  function sendNotification(componentProps, options) {
    return toast(
      <Notification
        {...componentProps}
        className={classes.notificationComponent}
      />,
      options
    );
  }

  function retryErrorNotification() {
    var componentProps = {
      type: "message",
      message: "Message was sent successfully!",
      variant: "contained",
      color: "success",
    };
    toast.update(errorToastId, {
      render: <Notification {...componentProps} />,
      type: "success",
    });
    setErrorToastId(null);
  }

  function handleNotificationCall(notificationType) {
    var componentProps;

    if (errorToastId && notificationType === "error") return;

    switch (notificationType) {
      case "info":
        componentProps = {
          type: "feedback",
          message: "New user feedback received",
          variant: "contained",
          color: "primary",
        };
        break;
      case "error":
        componentProps = {
          type: "message",
          message: "Message was not sent!",
          variant: "contained",
          color: "secondary",
          extraButton: "Resend",
          extraButtonClick: retryErrorNotification,
        };
        break;
      default:
        componentProps = {
          type: "shipped",
          message: "The item was shipped",
          variant: "contained",
          color: "success",
        };
    }

    var toastId = sendNotification(componentProps, {
      type: notificationType,
      position: positions[notificationsPosition],
      progressClassName: classes.progress,
      onClose: notificationType === "error" && (() => setErrorToastId(null)),
      className: classes.notification,
    });

    if (notificationType === "error") setErrorToastId(toastId);
  }

  function changeNotificationPosition(positionId) {
    setNotificationPosition(positionId);
  }
}

// #############################################################
function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
}
