import React, { useState } from "react";

import TextField from '@material-ui/core/TextField';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
    Grid,
    LinearProgress,
    Select,
    OutlinedInput,
    MenuItem,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

// styles
import useStyles from "../../styles";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';



export default function OtherSourceOfIncome(props) {
    var classes = useStyles();
    var theme = useTheme();


    return (


        <form className={classes.root + " " + classes.subformsc} noValidate autoComplete="off">
            <Grid container spacing={2}>
                
                <Grid item lg={12} md={12} sm={12} xs={12} className={classes.center_elements+ " " + classes.griditemc} >
                    <TextField
                    style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
                     InputProps={{
                        className: classes.inputcc + " " + classes.notchedOutline
                    }}
                    InputLabelProps={{
                        shrink: true,
                        className: classes.matlabelc
                    }} className={classes.textfieldvaluec} label="OTHER SOURCE OF INCOME" type="text"/>
                </Grid>
               
            </Grid>


        </form>



    );
}

