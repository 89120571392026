import React, { useState } from "react";

import TextField from '@material-ui/core/TextField';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
    Grid,
    LinearProgress,
    Select,
    OutlinedInput,
    MenuItem,
    Typography
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

// styles
import useStyles from "../../styles";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';



export default function EmployeeInformation(props) {
    var classes = useStyles();
    var theme = useTheme();


    return (


        <form className={classes.root + " " + classes.subformsc} noValidate autoComplete="off">
            <Grid container spacing={2}>
                <Grid item lg={3} md={4} sm={12} xs={12} className={classes.center_elements+ " " + classes.griditemc} >
                    <FormControl  style={{marginTop:"13px"}} component="fieldset" row>
                        <FormLabel className={classes.labeltextc} component="legend">Are you currently employee?</FormLabel>                        
                    </FormControl>
                </Grid>
                <Grid item lg={9} md={8} sm={12} xs={12} className={classes.center_elements+ " " + classes.griditemc} >
                    <FormControl component="fieldset" row>
                        <RadioGroup aria-label="radio-employee" name="radio-employee" row>
                            <FormControlLabel value="Yes" control={<Radio color="primary"/>}
                             label={<Typography className={classes.labeltextc}>Yes</Typography>}  />
                            <FormControlLabel value="No" control={<Radio color="primary"/>} 
                            label={<Typography className={classes.labeltextc}>No</Typography>} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12} className={classes.center_elements+ " " + classes.griditemc}>
                    <TextField
                    style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
                     InputProps={{
                        className: classes.inputcc + " " + classes.notchedOutline
                    }}
                    InputLabelProps={{
                        shrink: true,
                        className: classes.matlabelc
                    }}
                        className={classes.textfieldvaluec} 
                        // required
                         id="companyname_id" label="COMPANY NAME" type="text" placeholder="Quest GLT" autoComplete="first-name" />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12} className={classes.center_elements+ " " + classes.griditemc}>
                    <TextField
                    style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
                     InputProps={{
                        className: classes.inputcc + " " + classes.notchedOutline
                    }}
                    InputLabelProps={{
                        shrink: true,
                        className: classes.matlabelc
                    }} className={classes.textfieldvaluec} placeholder="IT COMPANY" 
                    // required
                     id="bank_address_id" label="COMPANY ACTIVITY" type="text"/>
                </Grid>

        
                  <Grid item lg={3} md={4} sm={6} xs={12} className={classes.center_elements+ " " + classes.griditemc}>
                    <TextField
                    style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
                     InputProps={{
                        className: classes.inputcc + " " + classes.notchedOutline
                    }}
                    InputLabelProps={{
                        shrink: true,
                        className: classes.matlabelc
                    }} className={classes.textfieldvaluec} placeholder="8974563258" 
                    // required
                     id="companyPhone_id" label="COMPANY PHONE" type="text" autoComplete="first-name" />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12} className={classes.center_elements+ " " + classes.griditemc}>
                <TextField
                style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
                // style={{width: 175}}
                        id="company_join_date"
                        label="COMPANY JOIN DATE"
                        type="date"
                        defaultValue="25-04-1997"
                        className={classes.textField}
                        InputProps={{
                            className: classes.inputcc + " " + classes.notchedOutline
                        }}
                        InputLabelProps={{
                            shrink: true,
                            className: classes.matlabelc
                        }}
                    />
                </Grid>
                {/* <Grid item lg={6} md={6} sm={6} xs={12} className={classes.center_elements+ " " + classes.griditemc}>
                    <TextField
                    style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
                     InputProps={{
                        className: classes.inputcc + " " + classes.notchedOutline
                    }}
                    InputLabelProps={{
                        shrink: true,
                        className: classes.matlabelc
                    }} 
                    // required
                     className={classes.textfieldvaluec} placeholder="202, Atulya IT Park, Khandwa Naka" id="company_address_id" label="COMPANY ADDRESS" type="text" autoComplete="first-name" />
                </Grid> */}
                  <Grid
          item
          lg={6}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
          style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
            style={{ width: "100%" }}
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
            className={classes.textfieldvaluec}
            placeholder="202, Atulya IT Park"
            // required
            id="company_address"
            label="COMPANY ADDRESS"
            type="text"
            autoComplete="first-name"
          />
        </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12} className={classes.center_elements+ " " + classes.griditemc}>
                    <TextField
                    style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
                     InputProps={{
                        className: classes.inputcc + " " + classes.notchedOutline
                    }}
                    InputLabelProps={{
                        shrink: true,
                        className: classes.matlabelc
                    }} className={classes.textfieldvaluec} placeholder="Indore" 
                    // required
                     id="city_id" label="CITY" type="text" autoComplete="first-name" />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12} className={classes.center_elements+ " " + classes.griditemc}>
                    <TextField
                    style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
                     InputProps={{
                        className: classes.inputcc + " " + classes.notchedOutline
                    }}
                    InputLabelProps={{
                        shrink: true,
                        className: classes.matlabelc
                    }} className={classes.textfieldvaluec} placeholder="Madhya Pradesh" 
                    // required
                     id="state_id" label="STATE" type="text" autoComplete="first-name" />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12} className={classes.center_elements+ " " + classes.griditemc}>
                    <TextField
                    style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
                     InputProps={{
                        className: classes.inputcc + " " + classes.notchedOutline
                    }}
                    InputLabelProps={{
                        shrink: true,
                        className: classes.matlabelc
                    }} className={classes.textfieldvaluec} placeholder="452014" 
                    // required
                     id="zip_id" label="ZIP" type="text" autoComplete="first-name" />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12} className={classes.center_elements+ " " + classes.griditemc}>
                    <TextField
                    style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
                     InputProps={{
                        className: classes.inputcc + " " + classes.notchedOutline
                    }}
                    InputLabelProps={{
                        shrink: true,
                        className: classes.matlabelc
                    }} className={classes.textfieldvaluec} placeholder="India" 
                    // required
                     id="country_id" label="COUNTRY" type="text" autoComplete="first-name" />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12} className={classes.center_elements+ " " + classes.griditemc}>
                    <TextField
                    style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
                     InputProps={{
                        className: classes.inputcc + " " + classes.notchedOutline
                    }}
                    InputLabelProps={{
                        shrink: true,
                        className: classes.matlabelc
                    }} className={classes.textfieldvaluec} placeholder="Graphic Designer" 
                    // required
                     id="occupation_id" label="OCCUPATION" type="text" autoComplete="first-name" />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12} className={classes.center_elements+ " " + classes.griditemc}>
                    <TextField
                    style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
                     InputProps={{
                        className: classes.inputcc + " " + classes.notchedOutline
                    }}
                    // style={{width: 200}}
                    InputLabelProps={{
                        shrink: true,
                        className: classes.matlabelc
                    }} className={classes.textfieldvaluec} placeholder="30000 INR" 
                    // required
                     id="gross_income_id" label="GROSS MONTHLY INCOME" type="text" autoComplete="first-name" />
                </Grid>
               
            </Grid>


        </form>



    );
}

