import React, { useState } from "react";

import { Close as CloseIcon } from "@material-ui/icons";
import { ToastContainer, toast } from "react-toastify";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import classnames from "classnames";
import "./Deposit.scss";
import DepositTable from "../dashboard/components/DepositTable/DepositTable";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  NativeSelect,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// styles
import "react-toastify/dist/ReactToastify.css";
import useStyles from "./styles";
import mock from "../dashboard/mock";
import { QRCode } from "react-qr-svg";
// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Notification from "../../components/Deposit/Deposit";
import { Typography, Button } from "../../components/Wrappers/Wrappers";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CanvasJSReact from "../../assets/canvasjs.react";
import search from "../../../src/assets/images/search.png";
import copy_1 from "../../assets/images/copy_1.png";
import logo from "../../assets/images/bitcoin.png";
import logo1 from "../../assets/images/Group56(1).png";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const positions = [
  toast.POSITION.TOP_LEFT,
  toast.POSITION.TOP_CENTER,
  toast.POSITION.TOP_RIGHT,
  toast.POSITION.BOTTOM_LEFT,
  toast.POSITION.BOTTOM_CENTER,
  toast.POSITION.BOTTOM_RIGHT,
];
const useStylesModal = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    borderRadius: 10,
  },
  paper: {
    backgroundColor: "white",
    // border: "20px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const recentTransaction = [
  {
    id: 1,
    btc: "BTC",
    bank: "Using - Bank ****** 5263",
    NValue: "-0.000242 BTC",
    PValue: "-0.125 USD",
  },
  {
    id: 2,
    btc: "BTC",
    bank: "Using - Bank ****** 5263",
    NValue: "-0.000242 BTC",
    PValue: "-0.125 USD",
  },
  {
    id: 3,
    btc: "BTC",
    bank: "Using - Bank ****** 5263",
    NValue: "-0.000242 BTC",
    PValue: "-0.125 USD",
  },
  {
    id: 4,
    btc: "BTC",
    bank: "Using - Bank ****** 5263",
    NValue: "-0.000242 BTC",
    PValue: "-0.125 USD",
  },
  {
    id: 5,
    btc: "BTC",
    bank: "Using - Bank ****** 5263",
    NValue: "-0.000242 BTC",
    PValue: "-0.125 USD",
  },
  {
    id: 6,
    btc: "BTC",
    bank: "Using - Bank ****** 5263",
    NValue: "-0.000242 BTC",
    PValue: "-0.125 USD",
  },
  {
    id: 6,
    btc: "BTC",
    bank: "Using - Bank ****** 5263",
    NValue: "-0.000242 BTC",
    PValue: "-0.125 USD",
  },
];

export default function NotificationsPage(props) {
  var classes = useStyles();
  const classesModal = useStylesModal();
  const [open, setOpen] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  function wallet() {
    console.log("props --- orders", props.history);
    props.history.push("/app/wallet");
    window.location.reload();

  }

  function orders() {
    console.log("props --- orders", props.history);
    props.history.push("/app/tables");
    window.location.reload();

  }

  function notifications() {
    console.log("props --- Dashboard", props.history);
    props.history.push("/app/notifications");
    window.location.reload();

  }

  // local
  var [notificationsPosition, setNotificationPosition] = useState(2);
  var [errorToastId, setErrorToastId] = useState(null);
  function dashboard() {
    props.history.push("/app/dashboard");
    window.location.reload();

  }
  const options = {
    // exportEnabled: true,
    // animationEnabled: true,

    data: [
      {
        type: "pie",
        startAngle: 75,
        toolTipContent: "<b>{label}</b>: {y}%",
        // showInLegend: "true",
        legendText: "{label}",
        indexLabelFontSize: 16,
        indexLabel: "{label} ({y}%)",
        dataPoints: [
          { y: 60, label: "BTC" },
          { y: 30, label: "USD" },
          { y: 4, label: "Others" },
          { y: 6, label: "XRP" },
        ],
      },
    ],
  };
  return (
    <div className="headerContainer">
      {/* <PageTitle title="Notifications" /> */}
      <Grid container spacing={4}>
        <ToastContainer
          className={classes.toastsContainer}
          closeButton={
            <CloseButton className={classes.notificationCloseButton} />
          }
          closeOnClick={false}
          progressClassName={classes.notificationProgress}
        />
        <Grid item xs={12} md={6} lg={3} className="mT">
          <Widget
            // title="RECENT LOGINS"
            disableWidgetMenu
          >
            <div className="RSBF">
              <p
                style={{
                  fontSize: 17.2,
                  fontWeight: 620,
                  color: "#1D275A",
                  marginTop: -10,
                }}
              >
                TOTAL BALANCE
              </p>
            </div>
            <div style={{ marginTop: -8 }}>
              <hr className="borderHR borderE" />
            </div>
            <div className="RSBF" style={{ marginBottom: -10 }}>
              <p style={{ fontSize: 20, color: "black" }}>0.000000 BTC</p>
            </div>
          </Widget>
        </Grid>
        <Grid item xs={12} md={6} lg={3} className="mT">
          <Widget
            // title="RECENT LOGINS"
            disableWidgetMenu
          >
            <div className="RSBF">
              <p
                style={{
                  fontSize: 17.2,
                  fontWeight: 620,
                  color: "#1D275A",
                  marginTop: -10,
                }}
              >
                IN ORDER
              </p>
            </div>
            <div style={{ marginTop: -8 }}>
              <hr className="borderHR borderE" />
            </div>
            <div className="RSBF" style={{ marginBottom: -10 }}>
              <p style={{ fontSize: 20, color: "black" }}>0.000000 BTC</p>
            </div>
          </Widget>
        </Grid>{" "}
        <Grid item xs={12} md={6} lg={3} className="mT">
          <Widget
            // title="RECENT LOGINS"
            disableWidgetMenu
          >
            <div className="RSBF">
              <p
                style={{
                  fontSize: 17.2,
                  fontWeight: 620,
                  color: "#1D275A",
                  marginTop: -10,
                }}
              >
                AVAILABLE BALANCE
              </p>
            </div>
            <div style={{ marginTop: -8 }}>
              <hr className="borderHR borderE" />
            </div>
            <div className="RSBF" style={{ marginBottom: -10 }}>
              <p style={{ fontSize: 20, color: "black" }}>0.000000 BTC</p>
            </div>
          </Widget>
        </Grid>
        <Grid item xs={12} md={6} lg={3} className="mT">
          <Widget
            // title="RECENT AUTHORISED DEVICES"
            disableWidgetMenu
          >
            <div className="RSBF">
              <div style={{ flexDirection: "row", display: "flex" }}>
                <p
                  style={{
                    fontSize: 17.2,
                    fontWeight: 620,
                    color: "#1D275A",
                    marginTop: -10,
                  }}
                >
                  USE EQUIVALENT
                </p>
              </div>
            </div>
            <div style={{ marginTop: -8 }}>
              <hr className="borderHR borderE" />
            </div>
            <div className="RSBF" style={{ marginBottom: -10 }}>
              <p className="BT">0.000000 BTC</p>
            </div>
          </Widget>
        </Grid>
      </Grid>
      <Grid container spacing={7}>
        <ToastContainer
          className={classes.toastsContainer}
          closeButton={
            <CloseButton className={classes.notificationCloseButton} />
          }
          closeOnClick={false}
          progressClassName={classes.notificationProgress}
        />

        <Grid item xs={12} md={6} lg={6} className="mT">
          <Widget
            // title="RECENT LOGINS"
            disableWidgetMenu
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: -8,
              }}
            >
              <p
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#1D275A",
                  marginTop: -8,
                }}
              >
                DEPOSIT
              </p>
            </div>
            <div style={{ marginTop: -20 }}>
              <hr
                className="borderHR"
                style={{ width: "120%", marginLeft: -30, color: "#F5F5F5" }}
              />
            </div>

            <div
              className="RSBF"
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "cecnter",
                marginTop: 60,
              }}
            >
              <span className="displayMobileNone displayTbletBlock displayNotebookBlock displayDesktopBlock">
                <Grid item style={{ width: 200 }}>
                  <FormControl className={classes.formControl}>
                    <div style={{ marginBottom: 20 }}>
                      <InputLabel
                        className={classes.matlabelc}
                        shrink
                        id="label"
                        style={{ marginBottom: 20 }}
                      >
                        Currency
                      </InputLabel>
                    </div>
                    <Select
                      placeholder="Select ID Types"
                      style={{
                        width: 220,
                        backgroundColor: "#F0F1F5",
                        borderRadius: 6,
                        height: 40,
                        paddingLeft: 20,
                        marginTop: 3,
                      }}
                      displayEmpty
                      className={classes.selectEmpty}
                      labelId="label"
                      id="select"
                    >
                      <MenuItem value="10">Bitcoin</MenuItem>
                      <MenuItem value="20">Ripple</MenuItem>
                      <MenuItem value="20">Litecoin</MenuItem>
                      <MenuItem value="20">INR</MenuItem>
                      <MenuItem value="20">USD</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </span>
              <span className="displayMobileNone displayTbletBlock displayNotebookBlock displayDesktopBlock">
                <Grid
                  item
                  style={{ width: 100, marginLeft: 14, marginTop: 24 }}
                >
                  <FormControl className={classes.formControl}>
                    <div
                      style={{
                        backgroundColor: "#F0F1F5",
                        height: 36,
                        width: 100,
                        justifyContent: "center",
                        alignItems: "center",
                        paddingBottom: 20,
                        borderRadius: 6,
                      }}
                    >
                      <div style={{ marginBottom: 10 }}>
                        <p
                          style={{
                            fontSize: 16,
                            paddingLeft: 4,
                          }}
                        >
                          {" "}
                          {"  125.00 USD"}
                        </p>
                      </div>
                    </div>
                  </FormControl>
                </Grid>
              </span>
            </div>
            <span className="displayMobileBlock displayTbletNone displayNotebookNone displayDesktopNone">
              <Grid item style={{ width: 200, marginLeft: 24 }}>
                <FormControl className={classes.formControl}>
                  <div style={{ marginBottom: 20 }}>
                    <InputLabel
                      className={classes.matlabelc}
                      shrink
                      id="label"
                      style={{ marginBottom: 20 }}
                    >
                      Currency
                    </InputLabel>
                  </div>
                  <Select
                    placeholder="Select ID Types"
                    style={{
                      width: 220,
                      backgroundColor: "#F0F1F5",
                      borderRadius: 6,
                      height: 40,
                      paddingLeft: 20,
                      marginTop: 3,
                    }}
                    displayEmpty
                    className={classes.selectEmpty}
                    labelId="label"
                    id="select"
                  >
                    <MenuItem value="10">Bitcoin</MenuItem>
                    <MenuItem value="20">Ripple</MenuItem>
                    <MenuItem value="20">Litecoin</MenuItem>
                    <MenuItem value="20">INR</MenuItem>
                    <MenuItem value="20">USD</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item style={{ width: 120, marginLeft: 24, marginTop: 16 }}>
                <FormControl className={classes.formControl}>
                  <div
                    style={{
                      backgroundColor: "#F0F1F5",
                      height: 36,
                      width: 100,
                      justifyContent: "center",
                      alignItems: "center",
                      paddingBottom: 20,
                    }}
                  >
                    <p style={{ fontSize: 16 }}>125.00 USD</p>
                  </div>
                </FormControl>
              </Grid>
            </span>

            <div className="monthlyLRP">
              <p style={{ color: "gray", fontSize: 16 }}>Monthly Limit </p>
              <p style={{ color: "black", fontSize: 16 }}>$ 49750 remaining </p>
            </div>
            <div className="SBRT RSBF">
              <Button
                variant="contained"
                colortheme="primary"
                className={classnames(classes.notificationCallButton)}
                style={{
                  backgroundColor: "#10D876",
                  color: "white",
                  fontSize: 16,
                  fontWeight: "bold",
                  width: "100%",
                }}
              >
                Deposit
              </Button>
            </div>
          </Widget>
        </Grid>
        <Grid item xs={12} md={6} lg={6} className="mT">
          <Widget
            // title="RECENT AUTHORISED DEVICES"
            disableWidgetMenu
          >
            <hr
              className="borderHR"
              style={{
                marginTop: 20,
                width: "120%",
                marginLeft: -30,
                color: "#F5F5F5",
              }}
            />

            <div className="topQr">
              <Grid container spacing={3}>
                <Grid item xs={5} sm={5} md={5} lg={5} xl={5} >
                  <div className="leftPQR">

                  <QRCode
                    className="QRCode mtoQR"
                    bgColor="#FFFFFF"
                    fgColor="#000000"
                    level="Q"
                    value="some text"
                  />
                  </div>
                </Grid>
                <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                  <p className="fontCoint">
                    Coins will be deposited after 6 network confirmations.
                  </p>
                  <p className="fontCointUntil">
                    Until 6 confirmations are made, an equivalent amount of your
                    assets will be temporarily unavailable for withdrawals.
                  </p>
                </Grid>
              </Grid>
              <div
                className="lefM"
             
              >
                <p className="secr">3Kni4yUUQDor3syXonyCUfHXaEGHxvTQwJ</p>
                <div className="mt-ve">
                  <img className="copySize" src={copy_1} alt="user" />
                </div>
              </div>
            </div>
          </Widget>
        </Grid>
      </Grid>
      <Grid>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Widget>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: -10,
              }}
            >
              <p
                style={{
                  paddingTop: 12,
                  paddingBottom: 10,
                  fontSize: 18,
                  color: "#1D275A",
                  fontWeight: "bold",
                  marginTop: -16,
                }}
              >
                RECENT TRANSACTIONS
              </p>
              <span className="displayDesktopBlock displayNotebookBlock displayTbletBlock displayMobileNone">
                <div
                  style={{
                    flexDirection: "row-reverse",
                    display: "flex",
                    marginRight: 20,
                    marginTop: -14,
                    width: 260,
                    height: 16,
                  }}
                >
                  <div style={{ width: 280, height: 40 }}>
                    <TextField
                      style={{ paddingBottom: 10 }}
                      label="search"
                      id="outlined-basic"
                      variant="outlined"
                    />
                  </div>
                  <div style={{ paddingRight: 14, position: "absolute" }}>
                    <img
                      style={{
                        height: 26,
                        width: 26,

                        padding: 4,
                        marginTop: 10,
                      }}
                      src={search}
                      alt="logo"
                    />
                  </div>
                  {/* <div style={{zIndex: 1, marginRight: 20}}>

                  </div> */}
                </div>
              </span>
            </div>
            <span className="displayMobileBlock displayTbletNone displayNotebookNone displayDesktopNone">
              <div
                style={{
                  marginTop: -30,
                  flexDirection: "row-reverse",
                  display: "flex",
                }}
              >
                <TextField
                  style={{ padding: 2, width: 200 }}
                  id="outlined-basic"
                  label="Search"
                  variant="outlined"
                />
                <div style={{ paddingRight: 14, position: "absolute" }}>
                  <img
                    style={{
                      height: 30,
                      width: 30,

                      padding: 4,
                      marginTop: 10,
                    }}
                    src={search}
                    alt="logo"
                  />
                </div>
              </div>
            </span>
            <div className="RLine">
              <hr className="borderHR RLine" />
            </div>
            <div className="tableMargin MBL">
              {recentTransaction.map((recent) => (
                <Grid container spacing={3} className="MB">
                  <Grid item xs={4} sm={4} md={1} lg={1} xl={1}>
                    <div style={{ marginTop: 10 }}>
                      <img
                        style={{
                          height: 30,
                          width: 30,
                          marginRight: 5,
                          marginTop: -3,
                        }}
                        src={logo1}
                        alt="logo"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={4} md={1} lg={1} xl={1}>
                    <div style={{ marginTop: 8 }}>
                      <Button
                        size="small"
                        className="px-2"
                        variant="contained"
                        style={{
                          backgroundColor: "#11D675",
                          fontWeight: "bold",
                          width: 60,
                          color: "white",
                        }}
                      >
                        {"BUY"}
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={4} md={2} lg={2} xl={2} className="MLF">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ marginTop: 10 }}>
                        <img
                          style={{
                            height: 30,
                            width: 30,
                            marginRight: 5,
                            marginTop: -3,
                          }}
                          src={logo}
                          alt="logo"
                        />
                      </div>

                      <p className="tableCSSTxt">{recent.btc}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <p className="tableCSSTxt">{recent.bank}</p>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={3}
                    className="nMLF"
                  >
                    <div>
                      <p className="tableCSSTxt" style={{ color: "#11D675" }}>
                        {recent.NValue}
                      </p>
                    </div>
                  </Grid>{" "}
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={1}
                    lg={1}
                    xl={1}
                    className="nMLF"
                  >
                    <div className="endC">
                      <p className="tableCSSTxt">{recent.PValue}</p>
                    </div>
                  </Grid>
                </Grid>
              ))}
            </div>
          </Widget>
        </Grid>
      </Grid>
      <div style={{ marginTop: 30, marginBottom: 10 }}>
        <p>Copyright 2020 BIThashEX | All Rights Reserved</p>
      </div>
    </div>
  );

  // #############################################################
  function sendNotification(componentProps, options) {
    return toast(
      <Notification
        {...componentProps}
        className={classes.notificationComponent}
      />,
      options
    );
  }

  function retryErrorNotification() {
    var componentProps = {
      type: "message",
      message: "Message was sent successfully!",
      variant: "contained",
      color: "success",
    };
    toast.update(errorToastId, {
      render: <Notification {...componentProps} />,
      type: "success",
    });
    setErrorToastId(null);
  }

  function handleNotificationCall(notificationType) {
    var componentProps;

    if (errorToastId && notificationType === "error") return;

    switch (notificationType) {
      case "info":
        componentProps = {
          type: "feedback",
          message: "New user feedback received",
          variant: "contained",
          color: "primary",
        };
        break;
      case "error":
        componentProps = {
          type: "message",
          message: "Message was not sent!",
          variant: "contained",
          color: "secondary",
          extraButton: "Resend",
          extraButtonClick: retryErrorNotification,
        };
        break;
      default:
        componentProps = {
          type: "shipped",
          message: "The item was shipped",
          variant: "contained",
          color: "success",
        };
    }

    var toastId = sendNotification(componentProps, {
      type: notificationType,
      position: positions[notificationsPosition],
      progressClassName: classes.progress,
      onClose: notificationType === "error" && (() => setErrorToastId(null)),
      className: classes.notification,
    });

    if (notificationType === "error") setErrorToastId(toastId);
  }

  function changeNotificationPosition(positionId) {
    setNotificationPosition(positionId);
  }
}

// #############################################################
function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
}
