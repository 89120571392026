import React from "react";
import MUIDataTable from "mui-datatables";

// components
import TextField from "@material-ui/core/TextField";
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import Table from "../dashboard/components/Wallet/Wallet";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Typography,
  NativeSelect,
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import useStyles from "../../../src/pages/personalverificarionform/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import "./Wallet.scss";
import { Button } from "../../components/Wrappers/Wrappers";

// data
import mock from "../dashboard/mock";
import search from "../../../src/assets/images/search.png";

const datatableData = [
  ["Joe James", "Example Inc.", "Yonkers", "NY"],
  ["John Walsh", "Example Inc.", "Hartford", "CT"],
];

const secretkey = [
  {
    id: 1,
    coin: "BCH",
    namelabel: "BCH",
    total: "0.0000",
    available: "0.0000",
    inorder: "0.0000",
    btcValue: "0.0000",
  },
  {
    id: 2,
    coin: "BCH",
    namelabel: "Bitcoin",
    total: "0.0000",
    available: "0.0000",
    inorder: "0.0000",
    btcValue: "0.0000",
  },
  {
    id: 3,
    coin: "ETH",
    namelabel: "Ethereum",
    total: "0.0000",
    available: "0.0000",
    inorder: "0.0000",
    btcValue: "0.0000",
  },
  {
    id: 4,
    coin: "BCH",
    namelabel: "BCH",
    total: "0.0000",
    available: "0.0000",
    inorder: "0.0000",
    btcValue: "0.0000",
  },
  {
    id: 5,
    coin: "BTC",
    namelabel: "Bitcoin",
    total: "0.0000",
    available: "0.0000",
    inorder: "0.0000",
    btcValue: "0.0000",
  },
  {
    id: 6,
    coin: "ETH",
    namelabel: "Ethereum",
    total: "0.0000",
    available: "0.0000",
    inorder: "0.0000",
    btcValue: "0.0000",
  },
];
export default function Tables(props) {
  var classes = useStyles();
  function orders() {
    console.log("props --- orders", props.history);
    props.history.push("/app/tables");
    window.location.reload();

  }
  function orderHistory() {
    console.log("props --- orders", props.history);
    props.history.push("/app/orderHistory");
    window.location.reload();

  }
  const gotodashboard = () => {
    props.history.push("/app/homeDashboard");
    window.location.reload();

  };
  return (
    <div className="headerContainer">
      <p onClick={gotodashboard} className="paddFontPadd">
        DASHBOARD > > WALLET
      </p>
      <Grid>
        <Grid item xs={12} className="passingView">
          <Widget>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: -50,
                marginLeft: -30,
                marginBottom: -20,
                justifyContent: "space-between",
              }}
            >
              <p
                className="Mtp_"
                onClick={orders}
                style={{
                  padding: 12,
                  fontSize: 18,
                  color: "#1D275A",
                  marginLeft: 20,
                  fontWeight: "bolder",
                }}
              >
                WALLETS
              </p>
              <span className="displayMobileNone displayDesktop displayNotebook displayTablet">
                <div
                  style={{
                    flexDirection: "row-reverse",
                    display: "flex",
                    marginRight: 20,
                    marginTop: 26,
                    width: 260,
                    height: 16,
                  }}
                >
                  <div style={{ width: 280, height: 40 }}>
                    <TextField
                      style={{ paddingBottom: 10 }}
                      label="search"
                      id="outlined-basic"
                      variant="outlined"
                    />
                  </div>
                  <div style={{ paddingRight: 14, position: "absolute" }}>
                    <img
                      style={{
                        height: 26,
                        width: 26,

                        padding: 4,
                        marginTop: 10,
                      }}
                      src={search}
                      alt="logo"
                    />
                  </div>
                  {/* <div style={{zIndex: 1, marginRight: 20}}>

                  </div> */}
                </div>
              </span>
            </div>

            <span className="displayMobile displayDesktopNone displayNotebookNone displayTabletNone">
              <div
                style={{
                  marginTop: 5,
                  flexDirection: "row-reverse",
                  display: "flex",
                  marginRight: 20
                }}
              >
                <TextField
                  style={{ padding: 2, width: 160 }}
                  id="outlined-basic"
                  label="Search"
                  variant="outlined"
                />
                <img
                  style={{
                    height: 26,
                    width: 26,
                    marginTop: 12,
                    padding: 4,
                    position: "absolute",
                  }}
                  src={search}
                  alt="logo"
                />
              </div>
            </span>
            <div style={{ marginTop: 16 }}>
              <hr
                className="borderHR"
                style={{ width: "130%", marginLeft: -20, color: "#F5F5F5" }}
              />
            </div>

            <div className="tableMargin">
              <Grid container spacing={3}>
                <Grid item xs={6} sm={4} md={3} lg={1} xl={1}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    COIN
                  </p>
                </Grid>
                <Grid item xs={6} sm={4} md={3} lg={1} xl={1}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    NAME
                  </p>
                </Grid>
                <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    TOTAL BALANCE
                  </p>
                </Grid>
                <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    AVAILABLE BALANCE
                  </p>
                </Grid>
                <Grid item xs={6} sm={4} md={3} lg={1} xl={1}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    IN ORDER
                  </p>
                </Grid>
                <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    BTC VALUE
                  </p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={12}
                  md={6}
                  lg={3}
                  xl={3}
                  className="lr6060"
                >
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    ACTIONS
                  </p>
                </Grid>{" "}
              </Grid>

              {secretkey.map((screstData) => (
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={4} md={3} lg={1} xl={1}>
                    <p className="tableCSSTxt">{screstData.coin}</p>
                  </Grid>
                  <Grid item xs={6} sm={4} md={3} lg={1} xl={1}>
                    <p className="tableCSSTxt">{screstData.namelabel}</p>
                  </Grid>
                  <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
                    <p className="tableCSSTxt">{screstData.total}</p>
                  </Grid>
                  <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
                    <p className="tableCSSTxt">{screstData.available}</p>
                  </Grid>
                  <Grid item xs={6} sm={4} md={3} lg={1} xl={1}>
                    <p className="tableCSSTxt">{screstData.inorder}</p>
                  </Grid>
                  <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
                    <p className="tableCSSTxt">{screstData.btcValue}</p>
                  </Grid>
                  <Grid item xs={6} sm={4} md={1} lg={1} xl={1}>
                    <div className="mar60">
                      <Button
                        size="small"
                        className="px-2 MR buttonCSS"
                        variant="contained"
                      >
                        {"DEPOSIT"}
                      </Button>
                    </div>
                  </Grid>{" "}
                  <Grid item xs={6} sm={4} md={1} lg={1} xl={1}>
                    <div className="mar40">
                      <Button
                        size="small"
                        className="px-2 MR buttonCSS"
                        variant="contained"
                      >
                        {"VIEW"}
                      </Button>
                    </div>
                  </Grid>{" "}
                  <Grid item xs={6} sm={4} md={1} lg={1} xl={1}>
                    <div className="mar20 marginTo10">
                      <Button
                        size="small"
                        className="px-2 MR buttonCSS"
                        variant="contained"
                      >
                        {"DELETE"}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              ))}
            </div>
          </Widget>
        </Grid>
      </Grid>
      <div style={{ marginTop: 30, marginBottom: 10, marginLeft: 40 }}>
        <p>Copyright 2020 BIThashEX | All Rights Reserved</p>
      </div>
    </div>
  );
}
