import React, { useState } from "react";

import TextField from "@material-ui/core/TextField";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";

import ContactDetails from "./components/contactdetails";
import LegalInformation from "./components/legalform";
import StockExchange from "./components/stockexchange";
import Ownership from "./components/ownership";
import AML from "./components/aml";
import UploadDocuments from "./components/uploaddocuments";

function getSteps() {
  return [
    "Contact Details",
    "Legal Information",
    "Stock Exchange",
    "Ownership",
    "AML",
    "Upload Documents",
  ];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return <ContactDetails />;
    case 1:
      return <LegalInformation />;
    case 2:
      return <StockExchange />;
    case 3:
      return <Ownership />;
    case 4:
      return <AML />;
    case 5:
      return <UploadDocuments />;
    default:
      return "Default";
  }
}

export default function Enterpriseverification(props) {
  var classes = useStyles();
  var theme = useTheme();

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    if (activeStep + 1 <= steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      handleReset();
      props.history.push("/app/dashboard");
    window.location.reload();

    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div style={window.innerWidth < 500 ?  {marginTop: 70} : {marginTop: 5}}>

    <div className={classes.maindivc}>
      <PageTitle title="Welcome back," bold="&nbsp;Maria Pascle" />

      <PageTitle uppercase="ENTERPRISE IDENTITY VERIFICATION" />
      {/* <div className={classes.root} style={{marginTop: 30}}> */}
      <div style={window.innerWidth > 500 ? {marginTop: 30, marginRight: 50} : {marginTop: 30}}>
        {window.innerWidth > 700 ? (
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel icon=" ">
                  <span className={classes.stepprtlabelc}>{label}</span>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        ) : (
          <div></div>
        )}

        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed
              </Typography>
              <Button onClick={handleReset}>Reset</Button>
            </div>
          ) : (
            <div style={{ backgroundColor: "#fff", width: "100%" }}>
              <Typography className={classes.instructions}>
                {getStepContent(activeStep)}
              </Typography>
              <div
                style={{ padding: "2rem 2rem 4rem 2rem" }}
                className={classes.stepperbuttonc}
              >
                {/* <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}
                >
                  Back
              </Button> */}
                <Button
                  style={{ backgroundColor: "#1D275A" }}
                  className={classes.float_right}
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </div>
            </div>
          )}
        </div>
        <div></div>
      </div>
    </div>
    </div>

  );
}
