import React, { useState } from "react";

import TextField from "@material-ui/core/TextField";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";

import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

// styles
import useStyles from "../../styles";
import '../../../personalverificarionform/components/uploaddocuments/index.scss'
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";

export default function UploadDocuments(props) {
  var classes = useStyles();
  var theme = useTheme();

  function onFileChange() {}

  function onFileUpload() {}
  return (
    <form
      className={classes.root + " " + classes.subformsc}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={2}>
        <Grid item md={4} className={classes.toppaddinggridc}>
          {/* <div className={classes.borderdivc}>
            <input
              type="file"
              id="files"
              style={{ display: "none", width: "100%" }}
            />
            <label for="files" style={{ fontSize: "0.65rem" }}>
              Driving lincense
            </label>
            <button
              className={classes.uploadbtnc}
              style={{ backgroundColor: "#1D275A" }}
              onClick={onFileUpload}
            >
              <span style={{ color: "#fff", fontSize: "0.75rem" }}>Upload</span>
            </button>
          </div> */}
           <div style={{ flexDirection: "row"}}>
            <label for="files" style={{ fontSize: "0.65rem" }}>
              Driving License
            </label>
            <div className={classes.borderdivc}>
              <button className="upload-Input" style={{ backgroundColor: 'white', borderColor: 'transparent', marginTop: -30, marginLeft: 80}}>
                <div style={{ marginLeft: -100, marginTop: -20 }}>
                  <input className="fileInput" type="file" />
                </div>
              </button>
            </div>
          </div>
        </Grid>
        <Grid item md={8} className={classes.toppaddinggridc}></Grid>

        <Grid item md={4} className={classes.toppaddinggridc}>
          {/* <div className={classes.borderdivc}>
            <input
              type="file"
              id="files"
              style={{ display: "none", width: "100%" }}
            />
            <label for="files" style={{ fontSize: "0.65rem" }}>
              Pasport cover
            </label>
            <button
              className={classes.uploadbtnc}
              style={{ backgroundColor: "#1D275A" }}
              onClick={onFileUpload}
            >
              <span style={{ color: "#fff", fontSize: "0.75rem" }}>Upload</span>
            </button>
          </div> */}
           <div style={{ flexDirection: "row"}}>
            <label for="files" style={{ fontSize: "0.65rem" }}>
              Passport Cover
            </label>
            <div className={classes.borderdivc}>
              <button className="upload-Input" style={{ backgroundColor: 'white', borderColor: 'transparent', marginTop: -30, marginLeft: 80}}>
                <div style={{ marginLeft: -100, marginTop: -20 }}>
                  <input className="fileInput" type="file" />
                </div>
              </button>
            </div>
          </div>
        </Grid>
        <Grid item md={4} className={classes.toppaddinggridc}>
          {/* <div className={classes.borderdivc}>
            <input
              type="file"
              id="files"
              style={{ display: "none", width: "100%" }}
            />
            <label for="files" style={{ fontSize: "0.65rem" }}>
              Pasport Inside
            </label>
            <button
              className={classes.uploadbtnc}
              style={{ backgroundColor: "#1D275A" }}
              onClick={onFileUpload}
            >
              <span style={{ color: "#fff", fontSize: "0.75rem" }}>Upload</span>
            </button>
          </div> */}
           <div style={{ flexDirection: "row"}}>
            <label for="files" style={{ fontSize: "0.65rem" }}>
              Passport Inside
            </label>
            <div className={classes.borderdivc}>
              <button className="upload-Input" style={{ backgroundColor: 'white', borderColor: 'transparent', marginTop: -30, marginLeft: 80}}>
                <div style={{ marginLeft: -100, marginTop: -20 }}>
                  <input className="fileInput" type="file" />
                </div>
              </button>
            </div>
          </div>
        </Grid>
        <Grid item md={4} className={classes.toppaddinggridc}>
          {/* <div className={classes.borderdivc}>
            <input
              type="file"
              id="files"
              style={{ display: "none", width: "100%" }}
            />
            <label for="files" style={{ fontSize: "0.65rem" }}>
              Selfie Picture
            </label>
            <button
              className={classes.uploadbtnc}
              style={{ backgroundColor: "#1D275A" }}
              onClick={onFileUpload}
            >
              <span style={{ color: "#fff", fontSize: "0.75rem" }}>Upload</span>
            </button>
          </div> */}
           <div style={{ flexDirection: "row"}}>
            <label for="files" style={{ fontSize: "0.65rem" }}>
              Selfie Picture
            </label>
            <div className={classes.borderdivc}>
              <button className="upload-Input" style={{ backgroundColor: 'white', borderColor: 'transparent', marginTop: -30, marginLeft: 80}}>
                <div style={{ marginLeft: -100, marginTop: -20 }}>
                  <input className="fileInput" type="file" />
                </div>
              </button>
            </div>
          </div>
          {window.innerWidth > 500 && 
           <p style={{ color: "#1D275A", fontSize: "0.65rem" }}>
           Print on a white paper type the date and sign then they hold the
           passport and paper and take a selfie picture
         </p>}
        </Grid>
        {window.innerWidth < 499 && 
           <p style={{ color: "#1D275A", fontSize: "0.65rem" }}>
           Print on a white paper type the date and sign then they hold the
           passport and paper and take a selfie picture
         </p>}
      </Grid>
    </form>
  );
}
