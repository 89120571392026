import React, { useState } from "react";

import TextField from "@material-ui/core/TextField";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Typography,
  NativeSelect,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

// styles
import useStyles from "../../styles";
import './index.scss'
// // components
// import Widget from "../../components/Widget";
// import PageTitle from "../../../components/PageTitle";
// import { Typography } from "../../components/Wrappers";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";

export default function PersonalData(props) {
  var classes = useStyles();
  var theme = useTheme();

  return (

    <form
      className={classes.root + " " + classes.subformsc}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={2}>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
          style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
            className={classes.textfieldvaluec}
            // required
            id="first_name"
            label="FIRST NAME"
            type="text"
            placeholder="Johnatan"
            autoComplete="first-name"
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
          style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}

            className={classes.textfieldvaluec}
            placeholder="Doe"
            // required
            id="last_name"
            label="LAST NAME"
            type="text"
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
          />
        </Grid>

        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <FormControl component="fieldset">
            <FormLabel style={{fontSize: 9}} className={classes.matlabelc} component="legend">
              GENDER
            </FormLabel>
            <RadioGroup
              row
              aria-label="position"
              name="position"
              defaultValue="top"
            >
              <FormControlLabel
                value="Male"
                control={<Radio color="primary" />}
                label={
                  <Typography className={classes.labeltextc}>Male</Typography>
                }
              />
              <FormControlLabel
                value="Female"
                control={<Radio color="primary" />}
                label={
                  <Typography className={classes.labeltextc}>Female</Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
          style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}

            // style={{ width: 180 }}
            id="date"
            label="DATE OF BIRTH"
            type="date"
            defaultValue="25-04-1997"
            className={classes.textField}
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
          />
        </Grid>

        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
          style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}

            className={classes.textfieldvaluec}
            placeholder="Indian"
            // required
            id="first_name"
            label="CITIZENSHIP"
            type="text"
            autoComplete="first-name"
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
          style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}

            // required
            id="first_name"
            className={classes.textfieldvaluec}
            placeholder="Indore"
            label="PLACE OF BIRTH (CITY)"
            type="text"
            autoComplete="first-name"
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
          style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}

            // required
            id="first_name"
            className={classes.textfieldvaluec}
            placeholder="India"
            label="PLACE OF BIRTH (INDIA)"
            type="text"
            autoComplete="first-name"
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
          />
        </Grid>
        {/* <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
          style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}

            // required
            id="first_name"
            className={classes.textfieldvaluec}
            placeholder="Madhya Pradesh"
            label="PLACE OF BIRTH (STATE)"
            type="text"
            autoComplete="first-name"
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
          />
        </Grid> */}
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
          style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}

            // required
            id="first_name"
            className={classes.textfieldvaluec}
            placeholder="India"
            label="PLACE OF BIRTH (COUNTRY)"
            type="text"
            autoComplete="first-name"
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
          />
        </Grid>

        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
          style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}

            // required
            id="first_name"
            label="MARITAL STATUS"
            className={classes.textfieldvaluec}
            placeholder="Unmarried"
            type="text"
            autoComplete="first-name"
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
          />
        </Grid>
        <Grid
        style={{marginTop: 20}}
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
            <InputLabel className={classes.matlabelc} shrink id="label">
              ID TYPES
            </InputLabel>
           <Select
           style={{width: '99%'}}
            placeholder="Select ID Types"
              // style={{ width: 180 }}
              //   labelId="demo-simple-select-placeholder-label-label"
              //   id="demo-simple-select-placeholder-label"
              //   value={"ID TYPES"}
              displayEmpty
              className={classes.selectEmpty}
              labelId="label"
              id="select"
            >
              <MenuItem value="10">None</MenuItem>
              <MenuItem value="20">Driving Licence</MenuItem>
            </Select>
        </Grid>
        {/* <Grid item lg={3} md={4} sm={6} xs={12}>
          <FormControl className={classes.formControl}>
            <InputLabel className={classes.matlabelc} shrink id="label">
              ID TYPES
            </InputLabel>
            <Select
            placeholder="Select ID Types"
              style={{ width: 180 }}
              //   labelId="demo-simple-select-placeholder-label-label"
              //   id="demo-simple-select-placeholder-label"
              //   value={"ID TYPES"}
              displayEmpty
              className={classes.selectEmpty}
              labelId="label"
              id="select"
            >
              <MenuItem value="10">None</MenuItem>
              <MenuItem value="20">Driving Licence</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
          style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}

            // required
            id="first_name"
            className={classes.textfieldvaluec}
            placeholder="SFSDF21324234"
            label="ID NUMBER"
            type="text"
            autoComplete="first-name"
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
          />
        </Grid>
        {/* <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
          style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}

            style={{ width: 180 }}
            id="date"
            label="ID EXPIRATION DATE"
            type="date"
            value="date"
            defaultValue="2017-05-24"
            className={classes.textField}
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
          />
        </Grid> */}
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
          style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}

            // style={{ width: 180 }}
            id="date"
            label="ID EXPIRATION DATE"
            type="date"
            defaultValue="25-04-1997"
            className={classes.textField}
            InputProps={{
              className: classes.inputc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
          />
        </Grid>

      </Grid>
    </form>
    
  );
}
