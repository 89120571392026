import React, { useState } from "react";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

import user from "../../assets/images/userdashboard.PNG";
import enterprise from "../../assets/images/enterprisedashboard.PNG";
import './Dashboard.scss'
export default function Dashboard(props) {
  var classes = useStyles();
  var theme = useTheme();

  // local
  function continuepersonalVarification(){
    console.log("props");
    props.history.push('/app/personalvarification')
    window.location.reload();

  }
  function continueenterpriseVarification(){
    console.log("props --- Dashboard", props.history);
    props.history.push('/app/enterprisevarification')
    window.location.reload();

  }
  function notifications(){
    console.log("props --- Dashboard", props.history);
    props.history.push('/app/notifications')
    window.location.reload();

  }
  function orders(){
    console.log("props --- orders", props.history);
    props.history.push('/app/tables')
    window.location.reload();

  }
  function orderHistory(){
    console.log("props --- orders", props.history);
    props.history.push('/app/orderHistory')
    window.location.reload();

  }
  function walletPage(){
    console.log("props --- orders", props.history);
    props.history.push('/app/wallet')
    window.location.reload();

  }
  

  return (
    <div   className="headerArea">


    <div className={classes.maindivc}>
      {/* <div style={{flexDirection: 'row', display: 'flex'}}>
        <p style={{color: '#1D275A', fontSize: 30}}>Welcome back , </p>
        <p style={{color: '#1D275A', fontSize: 30, fontWeight: 900, marginLeft: 2}}>Maria Pascle</p>
      </div> */}
            <PageTitle title="Welcome back," bold="&nbsp;Maria Pascle" />
            <div style={{marginTop: 30, marginBottom: 60}}> 
<PageTitle uppercase="SELECT IDENTITY VERIFICATION TYPE" />
</div>
      {/* <PageTitle  title="Welcome back," bold="&nbsp;Maria Pascle" /> */}
      {/* <div style={{marginTop: 30, marginBottom: 30}}> 

      <div style={{fontWeight: 900, fontSize: 16, color: '#1D275A'}}> SELECT IDENTITY VERIFICATION TYPE </div>
      </div> */}
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
         
        <Card className={classes.root +" "+ classes.cardshadowc} style={{ borderRadius: 10}}>
          <CardContent>
          <Grid container >
            <Grid item lg={2}props md={4} sm={6} xs={12} className={classes.center_elements} >
            <img src={user} alt="personal"/>
            </Grid>
            <Grid item lg={7} md={5} sm={6} xs={12} className={classes.center_elements} >
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.typo+" "+classes.boldweight} variant="h6" size="sm">
                PERSONAL
                </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} >
                <Typography className={classes.typo+" "+classes.lightweight} variant="h6" size="sm" style={{color: '#1D275A', fontSize: 14}}>
                  If you're an individual personn and want to verify your ID on Bithashex, continue
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12} className={classes.center_elements}>
              <Button variant="contained" className={classes.blue_button}  style={{height: 46, width: 140}} onClick={continuepersonalVarification}> Continue </Button>
            </Grid>
          </Grid>
          </CardContent>
        </Card>

        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12} style={{textAlign:"center"}}>
          <span className={classes.ortextc}>OR</span>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} >
        <Card className={classes.root+" "+ classes.cardshadowc} style={{ borderRadius: 10}}>
          <CardContent>
          <Grid container>
            <Grid item lg={2} md={4} sm={6} xs={12} className={classes.center_elements}>
            <img src={enterprise} alt="enterprise"/>
            </Grid>
            <Grid item lg={7} md={5} sm={6} xs={12} className={classes.center_elements}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.typo+" "+classes.boldweight} variant="h6" size="sm">
                ENTERPRISE
                </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} >
                <Typography className={classes.typo+" "+classes.lightweight} variant="h6" size="sm" style={{color: '#1D275A', fontSize: 14}}>
                  If you're an Enterprise and want to verify your ID on Bithashex, continue
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12} className={classes.center_elements}>
              <Button variant="contained" className={classes.blue_button} style={{height: 46, width: 140}} onClick={continueenterpriseVarification}> Continue </Button>
            </Grid>
          </Grid>
          </CardContent>
        </Card>
        </Grid>
      </Grid>
    </div>
    </div>

  );
}

