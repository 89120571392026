import React from "react";
import { Button } from "@material-ui/core";
// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";

export default function PageTitle(props) {
  var classes = useStyles();

  return (
    <div className={classes.pageTitleContainer}>
      <Typography className={classes.typo+" "+classes.lightweightB} style={{fontSize: 25, color: '#363437', fontWeight: 400}} size="sm">
        {props.title}
      </Typography>
      {props.bold && (
         <Typography className={classes.typo+" "+classes.boldweight} style={{fontSize: 25}} size="sm">
         {props.bold}
       </Typography>
      )}

        {props.uppercase && (
         <Typography style={{marginTop:"10px", fontSize: 20.5}} className={classes.typo+" "+classes.boldweight} variant="h7" size="sm">
         {props.uppercase}
       </Typography>
      )}
    </div>
  );
}
