import { makeStyles } from "@material-ui/styles";

const drawerWidth = 240;

export default makeStyles(theme => ({
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: "none",
    },
    sidedivc: {
        // height: "100vh",
        height: '100%',
        backgroundColor: "#fff",
        paddingTop: "6em",
        width: "5em",
        boxShadow:"0 0px 14px 0 rgba(0,0,0,0.2)",
    },
    sidedivimgc: {
        height: "3.9em",
        margin:"auto",
        padding:"0.6em 0.3em 0.6em 1.2em",
        marginTop:"0.5em"
    }
}));
