import { makeStyles } from "@material-ui/styles";
import { irBlack } from "react-syntax-highlighter/dist/esm/styles/hljs";

export default makeStyles(theme => ({
  pageTitleContainer: {
    display: "flex",
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(3),
  },
  typo: {
    color: irBlack,
  },
  lightweight: {
    fontWeight: "lighter !important",
    color:"#1D275A",
    letterSpacing: 1.2,
  },
  lightweightB: {
    // fontWeight: "lighter !important",
    color:"#1D275A",
    letterSpacing: 1.2,
  },
  boldweight: {
    fontWeight: "600 !important",
    color:"#1D275A",
    letterSpacing: 1.2,
  },
  button: {
    boxShadow: theme.customShadows.widget,
    textTransform: "none",
    "&:active": {
      boxShadow: theme.customShadows.widgetWide,
    },
  },
}));
