import React, { useState } from "react";

import { Close as CloseIcon } from "@material-ui/icons";
import { ToastContainer, toast } from "react-toastify";

import "./HomeDashboard.scss";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

import "react-toastify/dist/ReactToastify.css";
import useStyles from "./styles";
import { QRCode } from "react-qr-svg";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Notification from "../../components/HomeDashboard/HomeDashboard";
import { Button } from "../../components/Wrappers/Wrappers";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CanvasJSReact from "../../assets/canvasjs.react";
import close from "../../assets/images/close.png";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const positions = [
  toast.POSITION.TOP_LEFT,
  toast.POSITION.TOP_CENTER,
  toast.POSITION.TOP_RIGHT,
  toast.POSITION.BOTTOM_LEFT,
  toast.POSITION.BOTTOM_CENTER,
  toast.POSITION.BOTTOM_RIGHT,
];
const useStylesModal = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    borderRadius: 30,
  },
  paper: {
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const secretkey = [
  {
    id: 1,
    created: "6/1/2020 | 7:28:49 PM",
    label: "Abc",
    apiKeyN: "************ ************",
    apiKey: "MRRVIXJQKB WVATTVJYUV 4TJGNAVE4M DGGFKXQQDY MMUWKKS3IJ ",
    secretKey: "----BEGIN PUBLIC KEY ---- MIICIJANBgkqhki",
    secretKeyN: "************ ************",
  },
  {
    id: 2,
    created: "6/1/2020 | 7:28:49 PM",
    label: "Abc",
    apiKey: "************ ************",
    secretKey: "************ ************",
  },
];
export default function NotificationsPage(props) {
  var classes = useStyles();
  const classesModal = useStylesModal();
  const [open, setOpen] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  function wallet() {
    console.log("props --- orders", props.history);
    props.history.push("/app/wallet");
    window.location.reload();
  }

  function orders() {
    console.log("props --- orders", props.history);
    props.history.push("/app/tables");
    window.location.reload();
  }

  function notifications() {
    console.log("props --- Dashboard", props.history);
    props.history.push("/app/notifications");
    window.location.reload();
  }

  function withdraw() {
    console.log("props --- Dashboard", props.history);
    props.history.push("/app/withdraw");
    window.location.reload();
  }

  function deposit() {
    console.log("props --- Dashboard", props.history);
    props.history.push("/app/deposit");
    window.location.reload();
  }

  function trade() {
    console.log("props --- Dashboard", props.history);
    props.history.push("/app/trade");
    window.location.reload();
  }

  function dashboardshow() {}

  // local
  var [notificationsPosition, setNotificationPosition] = useState(2);
  var [errorToastId, setErrorToastId] = useState(null);
  function dashboard() {
    props.history.push("/app/dashboard");
    window.location.reload();

  }
  const options = {
    // exportEnabled: true,
    // animationEnabled: true,

    data: [
      {
        type: "pie",
        startAngle: 75,
        toolTipContent: "<b>{label}</b>: {y}%",
        // showInLegend: "true",
        legendText: "{label}",
        indexLabelFontSize: 16,
        indexLabel: "{label} ({y}%)",
        dataPoints: [
          { y: 60, label: "BTC", color: "#008BAA" },
          { y: 30, label: "USD", color: "#DF3041" },
          { y: 4, label: "Others", color: "#02004C" },
          { y: 6, label: "XRP", color: "#7EE7F7" },
        ],
      },
    ],
  };
  const containerProps = {
    height: "calc(100vh - 74vh)",
  };
  const containerPropssmall = {
    height: "calc(100vh - 80vh)",
  };
  console.log("log", secretkey);
  return (
    <div className="headerContainer">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <PageTitle title="Welcome back," bold="&nbsp;Maria Pascle" />
        </Grid>
        <Grid item display={{ xs: "none" }} sm={12} md={6} lg={6} xl={6}>
          <div style={{ flexDirection: "row-reverse", display: "flex" }}>
            <div onClick={notifications} className="raperHeaderText">
              <p className="headerText">AUTHORIZED DEVICES</p>
              <hr className="borderHRBold colorWidth" />
            </div>
            <div
              onClick={notifications}
              className="raperHeaderText tenPercentMR"
            >
              <p className="headerText">RECENT LOGINS</p>
              <hr className="borderHRBold colorWidth" />
            </div>
            <div
              onClick={notifications}
              className="raperHeaderText tenPercentMR"
            >
              <p className="headerText">ANNOUNCEMENTS</p>
              <hr className="borderHRBold colorWidth" />
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={7}>
        <ToastContainer
          className={classes.toastsContainer}
          closeButton={
            <CloseButton className={classes.notificationCloseButton} />
          }
          closeOnClick={false}
          progressClassName={classes.notificationProgress}
        />

        <Grid item xs={12} md={6} lg={6} className="WidgetTopRaper">
          <Widget disableWidgetMenu>
            <div className="widgetHeader">
              <p className="widgetHeaderChild" style={{ color: "#202357" }}>
                SUMMARY
              </p>
              <div onClick={wallet} className="rightArea">
                <p className="rightAreaFont">VIEW ALL</p>
                <hr className="borderHRBoldLine linePositionHR" />
              </div>
            </div>
            <div style={{ marginTop: -14 }}>
              <hr className="borderHR borderHRPercen" />
            </div>

            <div className="headerBTC">
              <div className="FC">
                <p className="grayF">Available BTC</p>
                <p className="avalBTC">0.02251455 BTC</p>
              </div>
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  marginRight: 25,
                }}
              >
                <p className="grayF">USD Equivalent</p>
                <p className="avalBTC">10 USD</p>
              </div>
            </div>
            <div className="MT14">
              <div className="MLR">
                <div className="FC">
                  <p
                    style={{
                      fontSize: 14,
                      color: "gray",
                    }}
                  >
                    Total Balance
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      color: "#2B3058",
                      fontWeight: 600,
                      marginTop: -15,
                    }}
                  >
                    78950.35 USD
                  </p>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    paddingRight: 4,
                  }}
                >
                  <p
                    style={{
                      fontSize: 14,
                      color: "gray",
                    }}
                  >
                    In Order
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      color: "#2B3058",
                      fontWeight: 600,
                      marginTop: -15,
                    }}
                  >
                    56845.25 USD
                  </p>
                </div>
              </div>
              <div className="M30butt">
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 30,
                  }}
                >
                  {/* Check Again */}
                  <Button
                    onClick={deposit}
                    size="small"
                    className="buttonsGBR buttonsGBRWidth backgroundGreen"
                    variant="contained"
                  >
                    Deposit
                  </Button>
                  <Button
                    onClick={withdraw}
                    size="small"
                    variant="contained"
                    className="buttonsGBR buttonsGBRWidth backgroundRed"
                  >
                    {"Withdraw"}
                  </Button>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 25,
                    marginBottom: 30,
                  }}
                >
                  <Button
                    onClick={trade}
                    size="small"
                    variant="contained"
                    className="buttonsGBR buttonsGBRWidth backgroundBlue"
                  >
                    {"Trade"}
                  </Button>
                  <Button
                    onClick={orders}
                    size="small"
                    variant="contained"
                    className="buttonsGBR buttonsGBRWidth backgroundBlue"
                  >
                    {"Order"}
                  </Button>{" "}
                </div>
              </div>
            </div>
          </Widget>
        </Grid>
        <Grid item xs={12} md={6} lg={6} className="WidgetTopRaper">
          <Widget
            // title="RECENT AUTHORISED DEVICES"
            disableWidgetMenu
          >
            <div
              className="CSSheaderMAr"
            >
              <div style={{ flexDirection: "row", display: "flex" }}>
                <p
                  style={{
                    fontSize: 19,
                    fontWeight: 600,
                    color: "#1E2059",
                    marginTop: -10,
                  }}
                >
                  MARIA PASCLE
                </p>
                <span className="MobileView">
                  <p
                    style={{
                      fontSize: 16,
                      color: "#212953",
                      marginTop: -8,
                      marginLeft: 4,
                    }}
                  >
                    (mariapascle@gmail.com)
                  </p>
                </span>
              </div>

              <div className="rightArea">
                <p className="rightAreaFont">EDIT</p>
                <hr className="borderHRBoldLine linePositionHR" />
              </div>
            </div>
            <span className="desktopView">
              <p
                style={{
                  fontSize: 16,
                  color: "#1D275A",
                  marginTop: -8,
                  marginLeft: 10,
                }}
              >
                (mariapascle@gmail.com)
              </p>
            </span>
            <div style={{ marginTop: -10 }}>
              <hr className="borderHR borderHRPercen" />
            </div>
            <span className="MobileView">
              <div style={{ marginBottom: 20 }}>
                <CanvasJSChart
                  containerProps={containerProps}
                  options={options}
                  /* onRef={ref => this.chart = ref} */
                />
              </div>
            </span>
            <span className="desktopView">
              <div style={{ marginLeft: 10, marginRight: 10 }}>
                <CanvasJSChart
                  containerProps={containerPropssmall}
                  options={options}
                  /* onRef={ref => this.chart = ref} */
                />
              </div>
            </span>
            <span className="MobileView">
              <div className="border">
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: 20,
                  }}
                >
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: 600,
                      fontSize: 12,
                    }}
                  >
                    GOOGLE AUTHENTICATION
                  </p>
                  <span
                    style={{
                      backgroundColor: "#EFF1FE",
                      flexDirection: "row",
                      display: "flex",
                      padding: 3,
                      height: 40,
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <Button
                      size="small"
                      className="px-2"
                      variant="contained"
                      style={{
                        height: 30,
                        backgroundColor: "#EFF1FE",
                        width: 70,
                        color: "#8c8baa",
                        marginRight: 5,
                        fontWeight: 900,
                        fontSize: 9,
                        marginTop: 2,
                      }}
                    >
                      {"DISABLE"}
                    </Button>{" "}
                    <Button
                      onClick={handleOpen}
                      size="small"
                      className="px-2"
                      variant="contained"
                      style={{
                        height: 30,
                        backgroundColor: "white",
                        width: 70,
                        color: "#1D275A",
                        fontWeight: 900,
                        fontSize: 9,
                        marginTop: 2,
                      }}
                    >
                      {"ENABLE"}
                    </Button>
                    <div style={{ borderRadius: 20 }}>
                      <Modal
                        style={{ borderRadius: 50 }}
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classesModal.modal}
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                          timeout: 500,
                        }}
                      >
                        <Fade in={open}>
                          <div className={classesModal.paper}>
                            <div
                              onClick={handleClose}
                              style={{
                                flexDirection: "row",
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: -40,
                              }}
                            >
                              <p style={{ color: "white" }}>.</p>
                              <div style={{ marginRight: -3 }}>
                                <img
                                  style={{ width: 15, height: 15 }}
                                  className={classes.sidedivimgc}
                                  src={close}
                                  alt="user"
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                                marginLeft: 180,
                              }}
                            >
                              <h2
                                id="transition-modal-title"
                                style={{ color: "#1D275A", fontSize: 32 }}
                              >
                                GOOGLE AUTHENTICATOR
                              </h2>
                            </div>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <QRCode
                                bgColor="#FFFFFF"
                                fgColor="#000000"
                                level="Q"
                                style={{ width: 180 }}
                                value="some text"
                              />
                              <p
                                style={{
                                  fontSize: 20,
                                  color: "black",
                                  marginTop: 80,
                                  marginLeft: 20,
                                }}
                              >
                                GRAVWUKKTKJEFI3DLMF4HMPRIKBHWML35
                              </p>
                            </div>
                            <p
                              id="transition-modal-description"
                              style={{
                                color: "#1D275A",
                                fontSize: 26,
                                marginTop: 40,
                              }}
                            >
                              Are you sure want to turn on Google Authentication
                              ?
                            </p>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginBottom: -20,
                              }}
                            >
                              <Button
                                onClick={handleClose}
                                size="small"
                                className="px-2"
                                variant="contained"
                                style={{
                                  height: 60,
                                  backgroundColor: "#1D275A",
                                  width: 370,
                                  color: "white",
                                  fontWeight: 900,
                                  marginLeft: -30,
                                  borderRadius: 16,
                                  fontSize: 16,
                                }}
                              >
                                {"YES"}
                              </Button>{" "}
                              <Button
                                onClick={handleClose}
                                size="small"
                                className="px-2"
                                variant="contained"
                                style={{
                                  height: 60,
                                  backgroundColor: "#1D275A",
                                  width: 370,
                                  color: "white",
                                  fontWeight: 900,
                                  marginLeft: 10,
                                  marginRight: -30,
                                  borderRadius: 16,
                                  fontSize: 16,
                                }}
                              >
                                {"NO"}
                              </Button>
                            </div>
                          </div>
                        </Fade>
                      </Modal>
                    </div>
                  </span>
                </div>
                <div style={{ position: "absolute", marginTop: -36 }}>
                  <p className="withdrawalsTextB paddingTopD10">
                    Used for withdrawals and security modicatons
                  </p>
                </div>
              </div>
            </span>

            <span className="desktopView">
              {window.innerWidth < 600 && (
                <div style={{ marginLeft: -30 , marginRight: -10 }}>
                  <div className="border">
                    <p style={{ color: "#1D275A", fontWeight: "bold" }}>
                      GOOGLE AUTHENTICATION
                    </p>
                    <span
                      style={{
                        backgroundColor: "#EFF1FE",
                        flexDirection: "row",
                        display: "flex",
                        padding: 3,
                        width: 140,
                      }}
                    >
                      <Button
                        size="small"
                        className="px-2"
                        variant="contained"
                        style={{
                          height: 30,
                          backgroundColor: "#EFF1FE",
                          width: 50,
                          color: "#8c8baa",
                          fontWeight: 900,
                          marginRight: 5,
                        }}
                      >
                        {"DISABLE"}
                      </Button>{" "}
                      <Button
                        onClick={handleOpen}
                        size="small"
                        className="px-2"
                        variant="contained"
                        style={{
                          height: 30,
                          backgroundColor: "white",
                          width: 50,
                          color: "#1D275A",
                          fontWeight: 900,
                        }}
                      >
                        {"ENABLE"}
                      </Button>
                      <div>
                        <Modal
                          aria-labelledby="transition-modal-title"
                          aria-describedby="transition-modal-description"
                          className={classesModal.modal}
                          open={open}
                          onClose={handleClose}
                          closeAfterTransition
                          BackdropComponent={Backdrop}
                          BackdropProps={{
                            timeout: 500,
                          }}
                        >
                          <Fade in={open}>
                            <div className={classesModal.paper}>
                              <div
                                onClick={handleClose}
                                style={{
                                  flexDirection: "row",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: -30,
                                }}
                              >
                                <p style={{ color: "white" }}>.</p>
                                <div>
                                  <img
                                    style={{ width: 15, height: 15 }}
                                    className={classes.sidedivimgc}
                                    src={close}
                                    alt="user"
                                  />
                                </div>
                              </div>
                              <h2
                                id="transition-modal-title"
                                style={{ color: "#1D275A", fontSize: 20 }}
                              >
                                GOOGLE AUTHENTICATOR
                              </h2>
                              <QRCode
                                bgColor="#FFFFFF"
                                fgColor="#000000"
                                level="Q"
                                style={{ width: 256 }}
                                value="some text"
                              />
                              <p
                                style={{
                                  fontSize: 13,
                                  color: "black",
                                }}
                              >
                                GRAVWUKKTKJEFI3DLMF4HMPRIKBHWML35
                              </p>
                              <p
                                id="transition-modal-description"
                                style={{ color: "#1D275A", fontSize: 14 }}
                              >
                                Are you sure want to turn on Google
                                Authentication ?
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginBottom: -20,
                                }}
                              >
                                <Button
                                  onClick={handleClose}
                                  size="small"
                                  className="px-2"
                                  variant="contained"
                                  style={{
                                    height: 50,
                                    backgroundColor: "#1D275A",
                                    width: 150,
                                    color: "white",
                                    fontWeight: 900,
                                  }}
                                >
                                  {"YES"}
                                </Button>{" "}
                                <Button
                                  onClick={handleClose}
                                  size="small"
                                  className="px-2"
                                  variant="contained"
                                  style={{
                                    height: 50,
                                    backgroundColor: "#1D275A",
                                    width: 150,
                                    color: "white",
                                    fontWeight: 900,
                                    marginLeft: 10,
                                  }}
                                >
                                  {"NO"}
                                </Button>
                              </div>
                            </div>
                          </Fade>
                        </Modal>
                      </div>
                    </span>

                    <p className="withdrawalsText mTop10">
                      Used for withdrawals and security modicatons
                    </p>
                  </div>
                </div>
              )}
            </span>

            <span className="MobileView">
              {window.innerWidth > 601 && (
                <div className="border">
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      className="paddingTopD"
                      style={{
                        color: "#1D275A",
                        fontWeight: 600,
                        marginLeft: 20,
                        fontSize: 12,
                      }}
                    >
                      IDENTITY VERIFICATION
                    </p>
                    <span
                      style={{
                        backgroundColor: "#EFF1FE",
                        flexDirection: "row",
                        display: "flex",
                        padding: 3,
                        height: 40,
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <Button
                        onClick={dashboard}
                        size="small"
                        className="px-2"
                        variant="contained"
                        style={{
                          height: 30,
                          backgroundColor: "#EFF1FE",
                          width: 100,
                          color: "#393C46",
                          marginRight: 5,
                          fontWeight: 900,
                          fontSize: 9,
                          marginTop: 2,
                        }}
                      >
                        {"UNVERIFIED"}
                      </Button>{" "}
                    </span>
                  </div>
                  <div
                    className="marginTopD"
                    style={{ position: "absolute", marginTop: -10 }}
                  >
                    <p className="securityText">
                      Used for withdrawals and security modicatons
                    </p>
                  </div>
                </div>
              )}
            </span>
            <span className="desktopView">
              <div style={{ marginLeft: -30 , marginRight: -10}}>
                <div className="border">
                  <p style={{ color: "#1D275A", fontWeight: "bold" }}>
                    IDENTITY VERIFICATION
                  </p>
                  <span
                    style={{
                      // backgroundColor: "#EFF1FE",
                      // flexDirection: "row",
                      // display: "flex",
                      // padding: 3,
                      // width: 100,
                    }}
                  >
                    <Button
                      onClick={dashboard}
                      size="small"
                      className="px-2"
                      variant="contained"
                      style={{
                        height: 30,
                        backgroundColor: "#EFF1FE",
                        paddingLeft: 50, paddingRight: 50,
                        paddingTop: 4,
                        paddingBottom: 12,
                        width: 50,
                        color: "#8c8baa",
                        fontWeight: 900,
                        marginLeft: 2,
                      }}
                    >
                      {"UNVERIFIED"}
                    </Button>{" "}
                  </span>
                  <p className="mTop10" style={{ color: "black" }}>
                    Used for withdrawals and security modicatons
                  </p>
                </div>
              </div>
            </span>
          </Widget>
        </Grid>
      </Grid>
      <Grid>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Widget>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <p
                className="secCSSpading"
              >
                SECRET KEYS
              </p>
              <span className="MobileView">
                {window.innerWidth > 600 && (
                  <div style={{ marginTop: -4 }}>
                    <Button
                      onClick={handleOpenAdd}
                      size="small"
                      className="px-2"
                      variant="contained"
                      style={{
                        height: 40,
                        backgroundColor: "#1D275A",
                        width: 120,
                        color: "white",
                        fontWeight: 900,
                        marginLeft: 10,
                        borderRadius: 10,
                        fontSize: 16,
                        marginTop: -20,
                      }}
                    >
                      {"ADD"}
                    </Button>
                    <div>
                      <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classesModal.modal}
                        open={openAdd}
                        onClose={handleCloseAdd}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                          timeout: 500,
                        }}
                      >
                        <Fade in={openAdd}>
                          <div className={classesModal.paper}>
                            <div
                              onClick={handleCloseAdd}
                              style={{
                                flexDirection: "row",
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: -60,
                              }}
                            >
                              <p style={{ color: "white" }}>.</p>
                              <div style={{ marginRight: -3 }}>
                                <img
                                  style={{ width: 15, height: 15 }}
                                  className={classes.sidedivimgc}
                                  src={close}
                                  alt="user"
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <h2
                                id="transition-modal-title"
                                style={{
                                  color: "#1D275A",
                                  fontSize: 30,
                                  fontWeight: 900,
                                  marginLeft: 20,
                                  marginBottom: 30,
                                }}
                              >
                                ADD SECRET KEY
                              </h2>
                            </div>

                            <Grid
                              item
                              lg={3}
                              md={4}
                              sm={6}
                              xs={12}
                              className={
                                classes.center_elements +
                                " " +
                                classes.griditemc
                              }
                            >
                              <TextField
                                className={classes.textfieldvaluec}
                                // required
                                id="label"
                                label="LABEL"
                                type="text"
                                placeholder="Test"
                                autoComplete="LABEL"
                                InputProps={{
                                  className:
                                    classes.inputc +
                                    " " +
                                    classes.notchedOutline,
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                  className: classes.matlabelc,
                                }}
                                style={{ width: 300, marginBottom: 16 }}
                              />
                            </Grid>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginBottom: -20,
                              }}
                            >
                              <Button
                                onClick={handleCloseAdd}
                                size="small"
                                className="px-2"
                                variant="contained"
                                style={{
                                  height: 50,
                                  backgroundColor: "#1D275A",
                                  width: 450,
                                  color: "white",
                                  fontWeight: 900,
                                  borderRadius: 10,
                                  marginLeft: -25,
                                  marginRight: -25,
                                  marginTop: 20,
                                  fontSize: 20,
                                }}
                              >
                                {"Submit"}
                              </Button>{" "}
                            </div>
                          </div>
                        </Fade>
                      </Modal>
                    </div>
                  </div>
                )}
              </span>
              <span className="desktopView">
                {window.innerWidth < 600 && (
                  <div>
                    <Button
                      onClick={handleOpenAdd}
                      size="small"
                      className="px-2"
                      variant="contained"
                      style={{
                        height: 30,
                        backgroundColor: "#1D275A",
                        width: 50,
                        color: "white",
                        fontWeight: 900,
                        marginLeft: 10,
                        borderRadius: 10,
                        fontSize: 16,
                        marginTop: -20,
                        marginRight: 20,
                      }}
                    >
                      {"ADD"}
                    </Button>
                    <div>
                      <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classesModal.modal}
                        open={openAdd}
                        onClose={handleCloseAdd}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                          timeout: 500,
                        }}
                      >
                        <Fade in={openAdd}>
                          <div className={classesModal.paper}>
                            <div
                              onClick={handleCloseAdd}
                              style={{
                                flexDirection: "row",
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: -60,
                              }}
                            >
                              <p style={{ color: "white" }}>.</p>
                              <div style={{ marginRight: -3 }}>
                                <img
                                  style={{ width: 15, height: 15 }}
                                  className={classes.sidedivimgc}
                                  src={close}
                                  alt="user"
                                />
                              </div>
                            </div>
                            <h2
                              id="transition-modal-title"
                              style={{
                                color: "#1D275A",
                                fontSize: 24,
                                fontWeight: 900,
                              }}
                            >
                              ADD SECRET KEY
                            </h2>

                            <Grid
                              item
                              lg={3}
                              md={4}
                              sm={6}
                              xs={12}
                              className={
                                classes.center_elements +
                                " " +
                                classes.griditemc
                              }
                            >
                              <TextField
                                className={classes.textfieldvaluec}
                                // required
                                id="label"
                                label="LABEL"
                                type="text"
                                placeholder="Test"
                                autoComplete="LABEL"
                                InputProps={{
                                  className:
                                    classes.inputc +
                                    " " +
                                    classes.notchedOutline,
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                  className: classes.matlabelc,
                                }}
                                style={{ width: 300, marginBottom: 16 }}
                              />
                            </Grid>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginBottom: -20,
                              }}
                            >
                              <Button
                                onClick={handleCloseAdd}
                                size="small"
                                className="px-2"
                                variant="contained"
                                style={{
                                  height: 50,
                                  backgroundColor: "#1D275A",
                                  width: 330,
                                  color: "white",
                                  fontWeight: 900,
                                }}
                              >
                                {"Submit"}
                              </Button>{" "}
                            </div>
                          </div>
                        </Fade>
                      </Modal>
                    </div>
                  </div>
                )}
              </span>
            </div>
            <div>
              <hr
                className="borderHR"
                style={{ width: "120%", marginLeft: -20, marginTop: -20 }}
              />
            </div>
            <div className="tableMargin">
              <Grid container spacing={3}>
                <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    CREATED
                  </p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  lg={1}
                  xl={1}
                  className="paddRig20"
                >
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    LABEL
                  </p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  lg={4}
                  xl={4}
                  className="paddRig20"
                >
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    API KEY
                  </p>
                </Grid>
                <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    SECRET KEY
                  </p>
                </Grid>
                <Grid item xs={6} sm={4} md={3} lg={1} xl={1}>
                  <div style={{ marginLeft: -40 }}></div>
                </Grid>{" "}
                <Grid item xs={6} sm={4} md={3} lg={1} xl={1}>
                  <div style={{ marginLeft: -20 }}></div>
                </Grid>
              </Grid>

              {secretkey.map((screstData) => (
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
                    <p className="tableCSSTxt">{screstData.created}</p>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={3}
                    lg={1}
                    xl={1}
                    className="paddRig20"
                  >
                    <p className="tableCSSTxt">{screstData.label}</p>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={3}
                    lg={4}
                    xl={4}
                    className="paddRig20"
                  >
                    <p className="LeftDesktop tableCSSTxt">
                      {screstData.apiKey}
                    </p>
                  </Grid>
                  <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
                    <p className="tableCSSTxt margLeft-8">
                      {screstData.secretKey}
                    </p>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={3}
                    lg={1}
                    xl={1}
                    className="margRight_8 margLeft_8"
                  >
                    <div className="mrginLeFoury">
                      <Button
                        onClick={dashboardshow}
                        size="small"
                        className="px-2 MR buttonCSS"
                        variant="contained"
                      >
                        {"VIEW"}
                      </Button>
                    </div>
                  </Grid>{" "}
                  <Grid item xs={6} sm={4} md={3} lg={1} xl={1}>
                    <div className="mrginLeTwenty">
                      <Button
                        onClick={dashboardshow}
                        size="small"
                        className="px-2 MR buttonCSS"
                        variant="contained"
                      >
                        {"DELETE"}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              ))}
            </div>
          </Widget>
        </Grid>
      </Grid>
      <div className="marginLRT">
        <p>Copyright 2020 BIThashEX | All Rights Reserved</p>
      </div>
    </div>
  );

  function sendNotification(componentProps, options) {
    return toast(
      <Notification
        {...componentProps}
        className={classes.notificationComponent}
      />,
      options
    );
  }

  function retryErrorNotification() {
    var componentProps = {
      type: "message",
      message: "Message was sent successfully!",
      variant: "contained",
      color: "success",
    };
    toast.update(errorToastId, {
      render: <Notification {...componentProps} />,
      type: "success",
    });
    setErrorToastId(null);
  }

  function handleNotificationCall(notificationType) {
    var componentProps;

    if (errorToastId && notificationType === "error") return;

    switch (notificationType) {
      case "info":
        componentProps = {
          type: "feedback",
          message: "New user feedback received",
          variant: "contained",
          color: "primary",
        };
        break;
      case "error":
        componentProps = {
          type: "message",
          message: "Message was not sent!",
          variant: "contained",
          color: "secondary",
          extraButton: "Resend",
          extraButtonClick: retryErrorNotification,
        };
        break;
      default:
        componentProps = {
          type: "shipped",
          message: "The item was shipped",
          variant: "contained",
          color: "success",
        };
    }

    var toastId = sendNotification(componentProps, {
      type: notificationType,
      position: positions[notificationsPosition],
      progressClassName: classes.progress,
      onClose: notificationType === "error" && (() => setErrorToastId(null)),
      className: classes.notification,
    });

    if (notificationType === "error") setErrorToastId(toastId);
  }

  function changeNotificationPosition(positionId) {
    setNotificationPosition(positionId);
  }
}

function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
}
