import React, { useState } from "react";

import TextField from "@material-ui/core/TextField";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

// styles
import useStyles from "../../styles";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";

export default function StockExchange(props) {
  var classes = useStyles();
  var theme = useTheme();

  return (
    <form
      className={classes.root + " " + classes.subformsc}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={2}>
        <Grid
          item
          lg={3}
          md={4}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
           style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
            InputProps={{
              className: classes.inputcc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              // shrink: true,
              className: classes.matlabelc + " " + classes.nonshrinklevalc,
            }}
            // style={{ marginTop: "-15px", width: "100%" }}
            className={classes.textfieldvaluec}
            id="security-name"
            label="NAME OF EXCHANGE(S)"
            type="text"
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
           style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
            InputProps={{
              className: classes.inputcc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              // shrink: true,
              className: classes.matlabelc + " " + classes.nonshrinklevalc,
            }}
            // style={{ marginTop: "-15px", width: "100%" }}
            className={classes.textfieldvaluec}
            id="sockexchange-listing-code"
            label="FULL NAME OF COMPANY'S SECURITIES"
            type="text"
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
           style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
            InputProps={{
              className: classes.inputcc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              // shrink: true,
              className: classes.matlabelc + " " + classes.nonshrinklevalc,
            }}
            // style={{ marginTop: "-15px", width: 200 }}
            // style={{ marginTop: "-15px", width: "100%" }}
            className={classes.textfieldvaluec}
            id="regulatoe name"
            label="STOCK EXCHANGE LISTING CODE"
            type="text"
          />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <FormControl component="fieldset" row>
            <FormLabel
              className={classes.labeltextc}
              style={{ fontSize: "0.7rem", lineHeight: 1.3 }}
              component="legend"
            >
              Does you banking licence permit you to conduct finincial
              institution related activities with citizen of,or in the local
              currency of , the country that issued the license ?
            </FormLabel>
          </FormControl>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <FormControl component="fieldset" style={{ width: "85%" }} row>
            <RadioGroup aria-label="trade" name="trade" row>
              <Grid container spacing={1} style={{ marginTop: "-50px" }}>
                <Grid
                  item
                  lg={3}
                  md={6}
                  sm={6}
                  xs={12}
                  className={classes.center_elements + " " + classes.griditemc}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio color="primary" />}
                    label={
                      <Typography className={classes.labeltextc}>
                        Yes
                      </Typography>
                    }
                  />
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={6}
                  sm={6}
                  xs={12}
                  className={classes.center_elements + " " + classes.griditemc}
                >
                  <FormControlLabel
                    value="No"
                    control={<Radio color="primary" />}
                    label={
                      <Typography className={classes.labeltextc}>No</Typography>
                    }
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
}
