import React, { useState } from "react";

import TextField from "@material-ui/core/TextField";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

// styles
import useStyles from "../../styles";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";

export default function BankInformation(props) {
  var classes = useStyles();
  var theme = useTheme();

  return (
    <form
      className={classes.root + " " + classes.subformsc}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={2}>
        <Grid
          item
          lg={3}
          md={6}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <FormControl style={{ marginTop: "13px" }} component="fieldset" row>
            <FormLabel className={classes.labeltextc} component="legend">
              How do you want to trade?
            </FormLabel>
          </FormControl>
        </Grid>
        <Grid
          item
          lg={9}
          md={6}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <FormControl component="fieldset" row>
            <RadioGroup aria-label="trade" name="trade" row>
              <FormControlLabel
                value="Fiat to Crypto"
                control={<Radio color="primary" />}
                label={
                  <Typography className={classes.labeltextc}>
                    Fiat to Crypto
                  </Typography>
                }
              />
              <FormControlLabel
                className={classes.labeltextc}
                value="Crypto to Crypto"
                control={<Radio color="primary" />}
                label={
                  <Typography className={classes.labeltextc}>
                    Crypto to Crypto
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
          style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
            InputProps={{
              className: classes.inputcc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
            className={classes.textfieldvaluec}
            // required
            id="bankname_id"
            label="BANK NAME"
            type="text"
            placeholder="CREDIT AGROCOLE S A"
            autoComplete="first-name"
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
          style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
            InputProps={{
              className: classes.inputcc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
            className={classes.textfieldvaluec}
            placeholder="12 PL DES ETATS UNIS"
            // required
            id="bank_address_id"
            label="BANK ADDRESS"
            type="text"
          />
        </Grid>

        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
          style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
            InputProps={{
              className: classes.inputcc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
            className={classes.textfieldvaluec}
            placeholder="12 PL DES ETATS UNIS"
            // required
            id="institution_id"
            label="INSTITUTION"
            type="text"
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
          style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
            InputProps={{
              className: classes.inputcc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
            className={classes.textfieldvaluec}
            placeholder="MONTROUGE CEDEX"
            // required
            id="branch_id"
            label="BRANCH / ROUTING / ACH"
            type="text"
          />
        </Grid>

        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
          style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
            className={classes.textfieldvaluec}
            placeholder="FR8787878989667876878"
            // required
            id="account_id"
            label="ACCOUNT/IBAN"
            type="text"
            autoComplete="first-name"
            InputProps={{
              className: classes.inputcc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
          />
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className={classes.center_elements + " " + classes.griditemc}
        >
          <TextField
          style={window.innerWidth > 500 ? {width: '100%'} : {width: '96%'}}
            // required

            className={classes.textfieldvaluec}
            placeholder="AGRIFRPP"
            id="zip_code"
            label="SWIFT CODE"
            type="text"
            autoComplete="first-name"
            InputProps={{
              className: classes.inputcc + " " + classes.notchedOutline,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.matlabelc,
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
}
