import React from "react";

export default function Signin(props) {

  return (
    <div style={{ backgroundColor: "#EFF1FE" }}>
   <p style={{fontSize: 40}}>Signin Page WIP</p>
    </div>
  );
}
