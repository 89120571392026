import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import HomeDashboard from "../../pages/HomeDashboard/HomeDashboard";
import Signup from "../../pages/Signup/Signup";
import Signin from "../../pages/Signup/Signin";
import Deposit from "../../pages/Deposit/Deposit";
import Trade from "../../pages/Trade/Trade";
import Limit from "../../pages/Trade/Limit";
import StopLimit from "../../pages/Trade/StopLimit";
import Stop from "../../pages/Trade/Stop";

import Withdraw from "../../pages/Withdraw/Withdraw";
import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
import orderHistory from "../../pages/orderHistory";
import Wallets from "../../pages/wallet/Wallet";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";
import Personalvarification from "../../pages/personalverificarionform";
import Enterprisevarification from "../../pages/entrpiseverificationform";

import SideDiv from "../../components/sidediv";
// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        {props.history.location.pathname === "/app/signup" ||
        props.history.location.pathname === "/app/signin" ? (
          <></>
        ) : (
          <>
            <Header history={props.history} />
            <SideDiv history={props.history} />
          </>
        )}
        {/* <Sidebar /> */}
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path="/app/dashboard" component={Dashboard} />
            <Route path="/app/signup" component={Signup} />
            <Route path="/app/signin" component={Signin} />
            <Route path="/app/typography" component={Typography} />
            <Route path="/app/tables" component={Tables} />
            <Route path="/app/orderHistory" component={orderHistory} />
            <Route path="/app/notifications" component={Notifications} />
            <Route path="/app/homeDashboard" component={HomeDashboard} />
            <Route path="/app/deposit" component={Deposit} />
            <Route path="/app/trade" component={Trade} />
            <Route path="/app/limit" component={Limit} />
            <Route path="/app/stopLimit" component={StopLimit} />
            <Route path="/app/stop" component={Stop} />
            <Route path="/app/withdraw" component={Withdraw} />
            <Route path="/app/wallet" component={Wallets} />
            <Route
              exact
              path="/app/ui"
              render={() => <Redirect to="/app/ui/icons" />}
            />
            <Route path="/app/ui/maps" component={Maps} />
            <Route path="/app/ui/icons" component={Icons} />
            <Route path="/app/ui/charts" component={Charts} />
            <Route
              path="/app/personalvarification"
              component={Personalvarification}
            />
            <Route
              path="/app/enterprisevarification"
              component={Enterprisevarification}
            />
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
