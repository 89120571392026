import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  label: {
    fontSize: "0.75rem",
  },
  subformsc: {
    backgroundColor: "#fff",
    padding: "0em 2em"
  },
  stepperbuttondivc: {
    backgroundColor: "#fff",
    width: "100%",
    padding: "2rem 2rem 4rem 2rem",
    // float:"right"
  },
  float_right: {
    float: "right"
  },

  maindivc: {
    padding: "0em 2em 2em 2em"
  },
  textfieldvaluec: {
    fontSize: "0.75rem",
    // fontWeight:"650"
    Color: "red"
  },
  matlabelc: {
    fontSize: "0.75rem",
    color: "#808080 	"
  },

  stepprtlabelc: {
    marginTop: "-45px",
    fontSize: "0.53rem",
    color: '#808080 	',
  },
  inputc: {
    fontWeight: "800",
    fontSize: "0.83rem",
  },
  inputcc: {
    fontWeight: "800",
    fontSize: "0.72rem",
  },
  notchedOutline: {
    borderColor: '#DFDFDF',
    borderWidth: 1,
    '&::focus': {
      borderColor: '#DFDFDF',
      borderWidth: 1
    },
    '&:focus-within': {
      borderColor: '#DFDFDF',
      borderWidth: 1
    },
    '&::after': {
      borderColor: '#DFDFDF',
      borderWidth: 1
    },
    '&::before': {
      borderColor: '#DFDFDF',
      borderWidth: 1
    },
  },

  uploadbtnc: {
    padding: "0.2em 2.5em",
    borderRadius: "5px",
    marginTop: "-7px",
    marginLeft:"40px"
  },
  borderdivc:{
    borderBottom: "1px solid #DFDFDF",
    padding:"0.3em"
  },
  toppaddinggridc:{
    marginTop:"3em"
  },
  griditemc:{
    marginTop:"1em"
  },
  labeltextc:{
    fontWeight: "800",
    fontSize: "0.83rem",
  },
 
}));
