import React from "react";

export default function Signup(props) {

  return (
    <div style={{ backgroundColor: "#EFF1FE" }}>
   <p style={{fontSize: 40}}>Signup Page WIP</p>
    </div>
  );
}
