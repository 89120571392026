import React, { useState } from "react";

import TextField from "@material-ui/core/TextField";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import PageTitle from "../../../../components/PageTitle";

import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

// styles
import useStyles from "../../styles";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";

export default function AML(props) {
  var classes = useStyles();
  var theme = useTheme();

  return (
    <form
      className={classes.root + " " + classes.subformsc}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={2}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <div>
            <p style={{ fontSize: 15, color: "#1D275A", fontWeight: 550 }}>
              AML OVERVIEW
            </p>
          </div>
          {/* <PageTitle  uppercase="AML OVERVIEW" /> */}
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Has your country established laws designed to prevent money
                laundering and terrorist financing?
              </Typography>
            }
          />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Is your institution subject to local AML laws/regulations?
              </Typography>
            }
          />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Is your institution a member of a group of companies? If yes,
                does the ultimate parent entity require that all members of its
                group apply common internal standards of AML controls?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
            <div>
            <p style={{ fontSize: 15, color: "#1D275A", fontWeight: 550 }}>
            POLICIES, PRACTICES AND PROCEDURE
            </p>
          </div>
          {/* <PageTitle uppercase="POLICIES, PRACTICES AND PROCEDURE" /> */}
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does the AML compliance programme require approval of your
                institution’s Board or a senior committee?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution have a legal and regulatory compliance
                programme that includes a designated compliance officer
                responsible for coordinating and overseeing an AML programme,
                which has been approved by senior management of your
                institution, on a day-to-day basis?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Has your institution developed written policies documenting the
                processes that they have in place to prevent, detect and report
                suspicious transactions that has been approved by senior
                management?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                In addition to inspections by the government
                supervisors/regulators, does your institution have an internal
                audit function or other independent third party that assesses
                AML policies and practices on a regular basis?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution have a policy prohibiting
                accounts/relationships with shell banks? (A shell bank is
                defined as a bank incorporated in a jurisdiction in which it has
                no physical presence and which is unaffiliated with a regulated
                financial group.)
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution have policies covering relationships with
                politically exposed persons consistent with industry best
                practices?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution have appropriate record retention
                procedures pursuant to applicable law?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution require that its AML policies and
                practices be applied to all branches and subsidiaries of your
                institution both in the home country and in locations outside of
                the home country?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <div>
            <p style={{ fontSize: 15, color: "#1D275A", fontWeight: 550 }}>
            MONITORING CONTROLS
            </p>
          </div>
          {/* <PageTitle uppercase="MONITORING CONTROLS" /> */}
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution monitor the account activity of its
                customers to detect unusual or suspicious activity?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution have a compliance or other control
                function (e.g. audit department) which reviews compliance with
                KYC and AML policies and procedures?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution monitor correspondent account activity
                against official lists?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution monitor customer account databases for
                terrorist names?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
           <div>
            <p style={{ fontSize: 15, color: "#1D275A", fontWeight: 550 }}>
            OTHER CONTROLS
            </p>
          </div>

          {/* <PageTitle uppercase="OTHER CONTROLS" /> */}
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution train staff on KYC and AML policies and
                procedures?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution comply with FATF recommendations?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution have a dedicated Money Laundering
                Reporting Officer, or similar?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution provide AML training to relevant employees
                that includes identification and reporting of transactions that
                must be reported to government authorities, examples of
                different forms of money laundering involving the institution’s
                products and services and internal policies to prevent money
                laundering?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                What is the frequency of AML training for staff?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                What is the frequency of testing for AML compliance by audit?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution retain records of its training sessions
                including attendance records and relevant training materials
                used?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution have policies to communicate new AML
                related laws or changes to existing AML related policies or
                practices to relevant employees?
              </Typography>
            }
          />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution apply enhanced due diligence policies and
                procedures in connection with higher risk clients e.g.
                politically exposed persons?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
            <div>
            <p style={{ fontSize: 15, color: "#1D275A", fontWeight: 550 }}>
            SHELL BANKS
            </p>
          </div>
          {/* <PageTitle uppercase="SHELL BANKS" /> */}
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution have a physical presence, in that it
                carries on the business of banking at a place which is a fixed
                address in a jurisdiction in which it is authorized to carry on
                that business?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution have policies that prohibit dealings with
                Shell Banks?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
           <div>
            <p style={{ fontSize: 15, color: "#1D275A", fontWeight: 550 }}>
            KNOW YOUR CUSTOMER, DUE DILIGENCE AND ENHANCED DUE DILIGENCE
            </p>
          </div>
          {/* <PageTitle uppercase="KNOW YOUR CUSTOMER, DUE DILIGENCE AND ENHANCED DUE DILIGENCE" /> */}
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Has your institution implemented systems for the identification
                of its customers, including customer information in the case of
                recorded transactions, account opening, etc. (for example; name,
                nationality, street address, telephone number, occupation,
                age/date of birth, number and type of valid official
                identification, as well as the name of the country/state that
                issued it)?
              </Typography>
            }
          />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution have a requirement to collect information
                regarding its customers’ business activities?
              </Typography>
            }
          />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution collect information and assess its
                corporate customers’ AML policies or practices?
              </Typography>
            }
          />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution have procedures to establish a record for
                each customer noting their respective identification documents
                and Know Your Customer Information collected at account openin
              </Typography>
            }
          />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution take steps to understand the normal and
                expected transactions of its customers based on a risk
                assessment?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <div>
            <p style={{ fontSize: 15, color: "#1D275A", fontWeight: 550 }}>
            REPORTABLE TRANSACTIONS AND PREVENTION AND DETECTION OF TRANSACTIONS WITH ILLEGALLY OBTAINED FUNDS
            </p>
          </div>
          {/* <PageTitle uppercase="REPORTABLE TRANSACTIONS AND PREVENTION AND DETECTION OF TRANSACTIONS WITH ILLEGALLY OBTAINED FUNDS" /> */}
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution have policies or practices for the
                identification and reporting of transactions that are required
                to be reported to the authorities?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution have procedures to identify transactions
                structured to avoid large cash reporting requirements?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 0 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution screen transactions for customers or
                transactions it deems to be of significantly high risk (which
                may include persons, entities or countries that are contained on
                lists issued by government/international bodies) to warrant
                special attention prior to completing any such transactions?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 10 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution screen transactions for customers or
                transactions it deems to be of significantly high risk (which
                may include persons, entities or countries that are contained on
                lists issued by government/international bodies) to warrant
                special attention prior to completing any such transactions?
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.center_elements + " " + classes.griditemcheckboxc}
        >
          <FormControlLabel
            style={
              window.innerWidth < 700 ? { marginTop: 20 } : { marginTop: 10 }
            }
            control={<Checkbox name="checkedB2" color="primary" />}
            label={
              <Typography className={classes.labeltextcG}>
                Does your institution screen transactions for customers or
                transactions it deems to be of significantly high risk (which
                may include persons, entities or countries that are contained on
                lists issued by government/international bodies) to warrant
                special attention prior to completing any such transactions?
              </Typography>
            }
          />
        </Grid>
      </Grid>
    </form>
  );
}
