import React, { useState } from "react";

import TextField from "@material-ui/core/TextField";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

// styles
import useStyles from "./styles";

// // components
// import Widget from "../../components/Widget";
// import PageTitle from "../../../components/PageTitle";
// import { Typography } from "../../components/Wrappers";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import dashboard from "../../assets/images/dashboard.png.jpeg";
import exchange from "../../assets/images/exchange-white.png.jpeg";
import user from "../../assets/images/user-white.png";
import logout from "../../assets/images/logout-blue.png";
import dashboardgrey from "../../assets/images/dashboard-grey.png";
import exchange1 from "../../assets/images/exchange.png";
import user1 from "../../assets/images/user.png";
import logout1 from "../../assets/images/logout.png";
import "./index.scss";

export default function SideDiv(props) {
  var classes = useStyles();
  var theme = useTheme();

  const gotodashboard = () => {
    props.history.push("/app/homeDashboard");
    window.location.reload();
  };

  const trade = () => {
    props.history.push("/app/trade");
    window.location.reload();

  };

  const logout = () => {
    props.history.push("/app/signin");
    window.location.reload();

  };

  return (
    <div>
      <span className="MobileView">
        <div className={classes.sidedivc}>
          <Grid container spacing={1}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {props.history.location.pathname === "/app/homeDashboard" ? (
                <img
                  onClick={gotodashboard}
                  className={classes.sidedivimgc}
                  src={dashboard}
                  alt="dashboard"
                />
              ) : (
                <div style={{ marginLeft: 5 }}>
                  <img
                    style={{ height: 40, width: 50 }}
                    onClick={gotodashboard}
                    className={classes.sidedivimgc}
                    src={dashboardgrey}
                    alt="dashboard"
                  />
                </div>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {props.history.location.pathname === "/app/trade" ||
              props.history.location.pathname === "/app/limit" ||
              props.history.location.pathname === "/app/stopLimit" ||
              props.history.location.pathname === "/app/stop" ||
              props.history.location.pathname === "/app/deposit" ||
              props.history.location.pathname === "/app/withdraw" ||
              props.history.location.pathname === "/app/tables" ||
              props.history.location.pathname === "/app/personalvarification" ||
              props.history.location.pathname ===
                "/app/enterprisevarification" ||
              props.history.location.pathname === "/app/dashboard" ||
              props.history.location.pathname === "/app/wallet" ||
              props.history.location.pathname === "/app/notifications" ||
              props.history.location.pathname === "/app/orderHistory" ? (
                <img
                  onClick={trade}
                  className={classes.sidedivimgc}
                  src={exchange}
                  alt="dashboard"
                />
              ) : (
                <div style={{ marginLeft: 5 }}>
                  <img
                    style={{ height: 40, width: 50 }}
                    onClick={trade}
                    className={classes.sidedivimgc}
                    src={exchange1}
                    alt="dashboard"
                  />
                </div>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {props.history.location.pathname === "/app/user" ? (
                <img
                  // onClick={gotodashboard}
                  className={classes.sidedivimgc}
                  src={user}
                  alt="dashboard"
                />
              ) : (
                <div style={{ marginLeft: 5 }}>
                  <img
                    style={{ height: 40, width: 50 }}
                    // onClick={gotodashboard}
                    className={classes.sidedivimgc}
                    src={user1}
                    alt="dashboard"
                  />{" "}
                </div>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {props.history.location.pathname === "/app/signin" ? (
                <img
                  onClick={logout}
                  className={classes.sidedivimgc}
                  src={logout}
                  alt="dashboard"
                />
              ) : (
                <div style={{ marginLeft: 5 }}>
                  <img
                    style={{ height: 40, width: 50 }}
                    onClick={logout}
                    className={classes.sidedivimgc}
                    src={logout1}
                    alt="dashboard"
                  />{" "}
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </span>
      <span className="desktopView">
        <div
          style={{
            position: "absolute",
            marginTop: 70,
            flexDirection: "row",
            display: "flex",
            marginLeft: 20,
            marginRight: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <div>
              {/* <img
                onClick={gotodashboard}
                style={{ height: 35, width: 35, marginRight: 20 }}
                src={dashboard}
                alt="dashboard"
              /> */}
              {props.history.location.pathname === "/app/homeDashboard" ? (
                <img
                  onClick={gotodashboard}
                  className={classes.sidedivimgc}
                  src={dashboard}
                  alt="dashboard"
                />
              ) : (
                <div style={{ marginLeft: 5 }}>
                  <img
                    style={{ height: 40, width: 50 }}
                    onClick={gotodashboard}
                    className={classes.sidedivimgc}
                    src={dashboardgrey}
                    alt="dashboard"
                  />
                </div>
              )}
            </div>
            <div>
              {/* <img
                onClick={trade}
                style={{ height: 35, width: 35, marginRight: 20 }}
                src={exchange}
                alt="exchange"
              />
               */}
              {props.history.location.pathname === "/app/trade" ||
              props.history.location.pathname === "/app/limit" ||
              props.history.location.pathname === "/app/stopLimit" ||
              props.history.location.pathname === "/app/stop" ||
              props.history.location.pathname === "/app/deposit" ||
              props.history.location.pathname === "/app/withdraw" ||
              props.history.location.pathname === "/app/tables" ||
              props.history.location.pathname === "/app/personalvarification" ||
              props.history.location.pathname ===
                "/app/enterprisevarification" ||
              props.history.location.pathname === "/app/dashboard" ||
              props.history.location.pathname === "/app/notifications" ||
              props.history.location.pathname === "/app/wallet" ||
              props.history.location.pathname === "/app/orderHistory" ? (
                <div style={{ marginTop: -6 }}>
                  <img
                    onClick={trade}
                    className={classes.sidedivimgc}
                    src={exchange}
                    alt="dashboard"
                  />
                </div>
              ) : (
                <div style={{ marginLeft: 5, marginTop: 6 }}>
                  <img
                    style={{ height: 40, width: 50 }}
                    onClick={trade}
                    className={classes.sidedivimgc}
                    src={exchange1}
                    alt="dashboard"
                  />
                </div>
              )}
            </div>
            <div>
              {/* <img
                style={{ height: 35, width: 35, marginRight: 20 }}
                src={user}
                alt="user"
              /> */}
              {props.history.location.pathname === "/app/user" ? (
                <img
                  // onClick={gotodashboard}
                  className={classes.sidedivimgc}
                  src={user}
                  alt="dashboard"
                />
              ) : (
                <div style={{ marginLeft: 5 }}>
                  <img
                    style={{ height: 40, width: 50 }}
                    // onClick={gotodashboard}
                    className={classes.sidedivimgc}
                    src={user1}
                    alt="dashboard"
                  />{" "}
                </div>
              )}
            </div>
            <div>
              {/* <img
                style={{ height: 35, width: 35, marginRight: 20 }}
                src={logout1}
                alt="logout"
              /> */}
              {props.history.location.pathname === "/app/signin" ? (
                <img
                  onClick={logout}
                  className={classes.sidedivimgc}
                  src={logout}
                  alt="dashboard"
                />
              ) : (
                <div style={{ marginLeft: 5 }}>
                  <img
                    style={{ height: 40, width: 50 }}
                    onClick={logout}
                    className={classes.sidedivimgc}
                    src={logout1}
                    alt="dashboard"
                  />{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </span>
    </div>
  );
}
