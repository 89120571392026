import React, { useState } from "react";

import TextField from '@material-ui/core/TextField';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

import {
    Grid,
    LinearProgress,
    Select,
    OutlinedInput,
    MenuItem,
    Typography
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

// styles
import useStyles from "../../styles";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';



export default function SourcesOfFund(props) {
    var classes = useStyles();
    var theme = useTheme();


    return (


        <form className={classes.root + " " + classes.subformsc} noValidate autoComplete="off">
            <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}  className={classes.center_elements+ " " + classes.griditemc} >
                <FormGroup>
                    <FormControlLabel
                        control={
                        <Checkbox
                            name="checkedB1"
                            color="primary"
                        />
                        }
                        label={<Typography className={classes.labeltextc}>By Retirement Plan, RA, or equity Trust</Typography>}
                    />

                    <FormControlLabel
                        control={
                        <Checkbox
                            name="checkedB2"
                            color="primary"
                        />
                        }
                        label={<Typography className={classes.labeltextc}>By Employment</Typography>}
                    />


                    <FormControlLabel
                        control={
                        <Checkbox
                            name="checkedB2"
                            color="primary"
                        />
                        }
                        label={<Typography className={classes.labeltextc}>By Line of Credit</Typography>}
                    />
                    

                    <FormControlLabel
                        control={
                        <Checkbox
                            name="checkedB2"
                            color="primary"
                        />
                        }
                        label={<Typography className={classes.labeltextc}>By the sale of peroperty or company</Typography>}
                    />


                </FormGroup>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={6} className={classes.center_elements+ " " + classes.griditemc} >
                <FormGroup>

                    <FormControlLabel
                        control={
                        <Checkbox
                            name="checkedB2"
                            color="primary"
                        />
                        }
                        label={<Typography className={classes.labeltextc}>By Mortgage on Property</Typography>}
                    />


                    <FormControlLabel
                        control={
                        <Checkbox
                            name="checkedB2"
                            color="primary"
                        />
                        }
                        label={<Typography className={classes.labeltextc}>By Own Business</Typography>}

                    />


                     <FormControlLabel
                        control={
                        <Checkbox
                            name="checkedB2"
                            color="primary"
                        />
                        }
                        label={<Typography className={classes.labeltextc}>By Heritage</Typography>}

                    />

                    <FormControlLabel
                        control={
                        <Checkbox
                            name="checkedB2"
                            color="primary"
                        />
                        }
                        label={<Typography className={classes.labeltextc}>Other</Typography>}

                    />
                <TextField
                style={window.innerWidth > 500 ? {width: '100%'} : {width: 230}}
                  InputProps={{
                    className: classes.inputcc + " " + classes.notchedOutline
                }}
                InputLabelProps={{
                    shrink: true,
                    className: classes.matlabelc
                }}
                // style={{marginTop:"18px"}}
                 className={classes.textfieldvaluec} id="enter_other_id" label="ENTER OTHER" type="text"/>

                </FormGroup>
                </Grid>
               
            </Grid>


        </form>



    );
}

