import React, { useState } from "react";

import { Close as CloseIcon, Directions } from "@material-ui/icons";
import { ToastContainer, toast } from "react-toastify";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import classnames from "classnames";
import "./Trade.scss";
import TradeTable from "../dashboard/components/TradeTable/TradeTable";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  NativeSelect,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// styles
import "react-toastify/dist/ReactToastify.css";
import useStyles from "./styles";
import mock from "../dashboard/mock";
import { QRCode } from "react-qr-svg";
// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Notification from "../../components/Trade/Trade";
import { Typography, Button } from "../../components/Wrappers/Wrappers";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CanvasJSReact from "../../assets/canvasjs.react";
import search from "../../../src/assets/images/search.png";
import up from "../../../src/assets/images/up.png";
import down from "../../../src/assets/images/down.png";

import red from "../../../src/assets/images/Group 56.png";
import green from "../../../src/assets/images/Group56(1).png";
import bluel from "../../../src/assets/images/litecoin.png";
import blueF from "../../../src/assets/images/ripple.png";
import bitcoin from "../../../src/assets/images/bitcoin.png";
import Graph from "./graph";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const positions = [
  toast.POSITION.TOP_LEFT,
  toast.POSITION.TOP_CENTER,
  toast.POSITION.TOP_RIGHT,
  toast.POSITION.BOTTOM_LEFT,
  toast.POSITION.BOTTOM_CENTER,
  toast.POSITION.BOTTOM_RIGHT,
];
const useStylesModal = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    borderRadius: 10,
  },
  paper: {
    backgroundColor: "white",
    // border: "20px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const defaultProps = {
  marginTop: 1.7,
  m: 1,
  borderColor: "#b8b8b8",
  border: 1,
  borderRadius: 4,
  style: { width: "80px", height: "41px" },
  position: "absolute",
};

const defaultProps1 = {
  marginTop: 1.7,
  m: 1,
  borderColor: "#b8b8b8",
  border: 1,
  borderRadius: 4,
  style: { width: "80px", height: "41px" },
  position: "absolute",
};

const defaultProps2 = {
  marginTop: 1.7,
  m: 1,
  borderColor: "#b8b8b8",
  border: 1,
  borderRadius: 4,
  style: { width: "80px", height: "41px" },
  position: "absolute",
};
const defaultProps3 = {
  marginTop: 1.7,
  m: 1,
  borderColor: "#b8b8b8",
  border: 1,
  borderRadius: 4,
  style: { width: "80px", height: "41px" },
  position: "absolute",
};
const defaultProps4 = {
  marginTop: 1.7,
  m: 1,
  borderColor: "#b8b8b8",
  border: 1,
  borderRadius: 4,
  style: { width: "56px", height: "41px" },
  position: "absolute",
};

const recentTransaction = [
  {
    id: 1,
    icon1C: red,
    icon2C: "#ED1B24",
    icon2CText: "SOLD",

    icon3S: bitcoin,
    text4C: "#ED1B24",
    btc: "BTC",
    bank: "Using - Bank ****** 5263",
    NValue: "-0.000242 BTC",
    PValue: "-0.125 USD",
  },
  {
    id: 2,
    icon1C: red,
    icon2C: "#ED1B24",
    icon2CText: "SOLD",
    icon3S: bluel,
    text4C: "#ED1B24",
    btc: "LTC",
    bank: "Using - Bank ****** 5263",
    NValue: "-0.000242 BTC",
    PValue: "-0.125 USD",
  },
  {
    id: 3,
    icon1C: green,
    icon2C: "#11D675",
    icon2CText: "BUY",
    icon3S: blueF,
    text4C: "#11D675",
    btc: "XRP",
    bank: "Using - Bank ****** 5263",
    NValue: "-0.000242 BTC",
    PValue: "-0.125 USD",
  },
  {
    id: 4,
    icon1C: red,
    icon2C: "#ED1B24",
    icon2CText: "SOLD",
    icon3S: bitcoin,
    text4C: "#ED1B24",
    btc: "BTC",
    bank: "Using - Bank ****** 5263",
    NValue: "-0.000242 BTC",
    PValue: "-0.125 USD",
  },
];

export default function NotificationsPage(props) {
  var classes = useStyles();
  const classesModal = useStylesModal();
  const [open, setOpen] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  function wallet() {
    console.log("props --- orders", props.history);
    props.history.push("/app/wallet");
    window.location.reload();

  }

  function orders() {
    console.log("props --- orders", props.history);
    props.history.push("/app/tables");
    window.location.reload();

  }

  function notifications() {
    console.log("props --- Dashboard", props.history);
    props.history.push("/app/notifications");
    window.location.reload();

  }

  function trade() {
    console.log("props --- orders", props.history);
    props.history.push("/app/trade");
    window.location.reload();

  }

  function limit() {
    console.log("props --- orders", props.history);
    props.history.push("/app/limit");
    window.location.reload();

  }

  function stopLimit() {
    console.log("props --- orders", props.history);
    props.history.push("/app/stopLimit");
    window.location.reload();

  }

  function stop() {
    console.log("props --- orders", props.history);
    props.history.push("/app/stop");
    window.location.reload();

  }

  // local
  var [notificationsPosition, setNotificationPosition] = useState(2);
  var [errorToastId, setErrorToastId] = useState(null);
  function dashboard() {
    props.history.push("/app/dashboard");
    window.location.reload();

  }
  const options = {
    // exportEnabled: true,
    // animationEnabled: true,

    data: [
      {
        type: "pie",
        startAngle: 75,
        toolTipContent: "<b>{label}</b>: {y}%",
        // showInLegend: "true",
        legendText: "{label}",
        indexLabelFontSize: 16,
        indexLabel: "{label} ({y}%)",
        dataPoints: [
          { y: 60, label: "BTC" },
          { y: 30, label: "USD" },
          { y: 4, label: "Others" },
          { y: 6, label: "XRP" },
        ],
      },
    ],
  };

  const containerProps = {
    height: "calc(100vh - 150px)",
  };
  return (
    <div className="headerContainer">
      {/* <PageTitle title="Notifications" /> */}
      <Grid container spacing={4}>
        <ToastContainer
          className={classes.toastsContainer}
          closeButton={
            <CloseButton className={classes.notificationCloseButton} />
          }
          closeOnClick={false}
          progressClassName={classes.notificationProgress}
        />
        <Grid item xs={12} md={6} lg={3} className="MTtz">
          <Widget
            // title="RECENT LOGINS"
            disableWidgetMenu
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <p
                style={{
                  fontSize: 18,
                  fontWeight: "bolder",
                  color: "#1E2459",
                  marginTop: -12,
                }}
              >
                YOUR PORTFOLIO
              </p>
            </div>
            <div style={{ marginTop: -12 }}>
              <hr
                className="borderHR"
                style={{ width: "140%", marginLeft: -30, color: "#F5F5F5" }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <p style={{ fontSize: 20, color: "#313131", fontWeight: "600" }}>
                $63411.00
              </p>
              <div style={{ marginTop: 10, marginLeft: 4 }}>
                <p style={{ fontSize: 14, color: "#313131" }}>
                  (Total Balance)
                </p>
              </div>
            </div>
          </Widget>
        </Grid>{" "}
        <Grid item xs={12} md={6} lg={3} className="MTtz">
          <Widget
            // title="RECENT LOGINS"
            disableWidgetMenu
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <p
                style={{
                  fontSize: 18,
                  fontWeight: "bolder",
                  color: "#1E2459",
                  marginTop: -12,
                }}
              >
                BITCOIN
              </p>
            </div>
            <div style={{ marginTop: -12 }}>
              <hr
                className="borderHR"
                style={{ width: "140%", marginLeft: -30, color: "#F5F5F5" }}
              />
            </div>
            <p style={{ fontSize: 20, color: "#313131", fontWeight: "600" }}>
              0.000242 BTC
            </p>
            <p style={{ fontSize: 14, color: "#313131", marginTop: -15 }}>
              0.125 USD
            </p>
          </Widget>
        </Grid>{" "}
        <Grid item xs={12} md={6} lg={3} className="MTtz">
          <Widget
            // title="RECENT LOGINS"
            disableWidgetMenu
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <p
                style={{
                  fontSize: 18,
                  fontWeight: "bolder",
                  color: "#1E2459",
                  marginTop: -12,
                }}
              >
                LITE COIN
              </p>
            </div>
            <div style={{ marginTop: -12 }}>
              <hr
                className="borderHR"
                style={{ width: "140%", marginLeft: -30, color: "#F5F5F5" }}
              />
            </div>
            <p style={{ fontSize: 20, color: "#313131", fontWeight: "600" }}>
              0.000242 LTC
            </p>
            <p style={{ fontSize: 14, color: "#313131", marginTop: -15 }}>
              0.125 USD
            </p>
          </Widget>
        </Grid>
        <Grid item xs={12} md={6} lg={3} className="MTtz">
          <Widget
            // title="RECENT AUTHORISED DEVICES"
            disableWidgetMenu
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ flexDirection: "row", display: "flex" }}>
                <p
                  style={{
                    fontSize: 18,
                    fontWeight: "bolder",
                    color: "#1E2459",
                    marginTop: -12,
                  }}
                >
                  RIPPLE
                </p>
              </div>
            </div>
            <div style={{ marginTop: -12 }}>
              <hr
                className="borderHR"
                style={{ width: "140%", marginLeft: -30, color: "#F5F5F5" }}
              />
            </div>
            <p style={{ fontSize: 20, color: "#313131", fontWeight: "600" }}>
              0.000242 XRP
            </p>
            <p style={{ fontSize: 14, color: "#313131", marginTop: -15 }}>
              0.125 USD
            </p>
          </Widget>
        </Grid>
      </Grid>
      {/* cut code */}

      {/* <Grid container spacing={2} style={{ marginTop: 20 }}>
        <ToastContainer
          className={classes.toastsContainer}
          closeButton={
            <CloseButton className={classes.notificationCloseButton} />
          }
          closeOnClick={false}
          progressClassName={classes.notificationProgress}
        />
        <Grid item xs={12} sm={12} md={3} lg={3} className="MTtz">
          <Widget
            // title="RECENT LOGINS"
            disableWidgetMenu
          >
            <p>hi</p>
          </Widget>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} className="MTtz">
          <Widget
            // title="RECENT LOGINS"
            disableWidgetMenu
          >
            <p>hi</p>
          </Widget>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className="MTtz">
          <Widget
            // title="RECENT LOGINS"
            disableWidgetMenu
          >
            <p>hiq</p>
          </Widget>
        </Grid>
      </Grid> 

      {/*  */}
      {/* Except Tablet View */}
      <div className="MLR__map tabletDisplayNone mobileDisplay  notebookDisplay desktopDisplay">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="MT_map"
                  >
                    <Paper className="marginR2__map paddingLR drop3iconH">
                      <div className="drop3icon">
                        <Grid item className="drop3iconWidth">
                          <FormControl className={classes.formControl}>
                            <div className="drop3iconMTML">
                              <div className="mobileDisplay tabletDisplayNone notebookDisplayNone desktopDisplayNone">
                                <Box
                                  borderColor="primary.main"
                                  {...defaultProps1}
                                />
                              </div>
                              <div className="mobileDisplayNone tabletDisplay notebookDisplayNone desktopDisplayNone">
                                <Box
                                  borderColor="primary.main"
                                  {...defaultProps2}
                                />
                              </div>
                              <div className="mobileDisplayNone tabletDisplayNone notebookDisplay desktopDisplayNone">
                                <Box
                                  borderColor="primary.main"
                                  {...defaultProps3}
                                />
                              </div>
                              <div className="mobileDisplayNone tabletDisplayNone notebookDisplayNone desktopDisplay">
                                <Box
                                  borderColor="primary.main"
                                  {...defaultProps4}
                                />
                              </div>
                            </div>
                            <div style={{ marginTop: 10, marginLeft: 0 }}>
                              <Select
                                placeholder="Select ID Types"
                                className="textWidthS"
                                displayEmpty
                                // className={classes.selectEmpty}
                                labelId="label"
                                id="select"
                              >
                                <MenuItem value="10">None</MenuItem>
                                <MenuItem value="20">BTC</MenuItem>
                              </Select>
                            </div>
                          </FormControl>
                        </Grid>{" "}
                        <Grid item className="wid1020">
                          <FormControl className={classes.formControl}>
                            <div style={{ marginTop: 10 }}>
                              <div className="mobileDisplay tabletDisplayNone notebookDisplayNone desktopDisplayNone">
                                <Box
                                  borderColor="primary.main"
                                  {...defaultProps1}
                                />
                              </div>
                              <div className="mobileDisplayNone tabletDisplay notebookDisplayNone desktopDisplayNone">
                                <Box
                                  borderColor="primary.main"
                                  {...defaultProps2}
                                />
                              </div>
                              <div className="mobileDisplayNone tabletDisplayNone notebookDisplay desktopDisplayNone">
                                <Box
                                  borderColor="primary.main"
                                  {...defaultProps3}
                                />
                              </div>
                              <div className="mobileDisplayNone tabletDisplayNone notebookDisplayNone desktopDisplay">
                                <Box
                                  borderColor="primary.main"
                                  {...defaultProps4}
                                />
                              </div>
                            </div>
                            <div style={{ marginTop: 10, marginLeft: 8 }}>
                              <Select
                                placeholder="Select ID Types"
                                className="textWidthS"
                                displayEmpty
                                // className={classes.selectEmpty}
                                labelId="label"
                                id="select"
                              >
                                <MenuItem value="10">None</MenuItem>
                                <MenuItem value="20">ETH</MenuItem>
                              </Select>
                            </div>
                          </FormControl>
                        </Grid>{" "}
                        <Grid item className="wid2010">
                          <FormControl className={classes.formControl}>
                            <div style={{ marginTop: 10 }}>
                              <div className="mobileDisplay tabletDisplayNone notebookDisplayNone desktopDisplayNone">
                                <Box
                                  borderColor="primary.main"
                                  {...defaultProps1}
                                />
                              </div>
                              <div className="mobileDisplayNone tabletDisplay notebookDisplayNone desktopDisplayNone">
                                <Box
                                  borderColor="primary.main"
                                  {...defaultProps2}
                                />
                              </div>
                              <div className="mobileDisplayNone tabletDisplayNone notebookDisplay desktopDisplayNone">
                                <Box
                                  borderColor="primary.main"
                                  {...defaultProps3}
                                />
                              </div>
                              <div className="mobileDisplayNone tabletDisplayNone notebookDisplayNone desktopDisplay">
                                <Box
                                  borderColor="primary.main"
                                  {...defaultProps4}
                                />
                              </div>
                            </div>
                            <div style={{ marginTop: 10, marginLeft: 8 }}>
                              <Select
                                placeholder="Select ID Types"
                                className="textWidthS"
                                displayEmpty
                                // className={classes.selectEmpty}
                                labelId="label"
                                id="select"
                              >
                                <MenuItem value="10">None</MenuItem>
                                <MenuItem value="20">UAD</MenuItem>
                              </Select>
                            </div>
                          </FormControl>
                        </Grid>
                        <div className="mar820">
                          <img
                            style={{
                              height: 24,
                              width: 24,

                              padding: 4,
                              marginTop: 13,
                            }}
                            src={search}
                            alt="logo"
                          />
                        </div>
                      </div>
                      <hr
                        className="borderHR"
                        style={{
                          width: "140%",
                          marginLeft: -30,
                          marginBottom: -5,
                        }}
                      />
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: -10,
                        }}
                      >
                        <div style={{ flexDirection: "row", display: "flex" }}>
                          <p style={{ fontSize: 14, fontWeight: "bold" }}>
                            Pair
                          </p>
                          <div style={{ marginTop: 6, marginLeft: 3 }}>
                            <img
                              style={{
                                height: 14,
                                width: 14,

                                padding: 4,
                                marginTop: 10,
                              }}
                              src={down}
                              alt="logo"
                            />
                          </div>
                        </div>
                        <p style={{ fontSize: 14, fontWeight: "bold" }}>
                          Price
                        </p>
                        <p style={{ fontSize: 14, fontWeight: "bold" }}>
                          Change
                        </p>
                      </div>
                      <hr
                        className="borderHR"
                        style={{
                          width: "140%",
                          marginLeft: -30,
                          marginTop: -5,
                        }}
                      />
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                          height: 36,
                        }}
                      >
                        <p style={{ fontSize: 13 }}>BCH/BTC</p>
                        <p style={{ fontSize: 13 }}>0.03810000</p>
                        <p style={{ fontSize: 13, color: "#11D675" }}>0.00%</p>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                          height: 36,
                        }}
                      >
                        <p style={{ fontSize: 13 }}>BCH/BTC</p>
                        <p style={{ fontSize: 13 }}>0.03810000</p>
                        <p style={{ fontSize: 13 }}>1.00%</p>
                      </div>{" "}
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                          height: 36,
                        }}
                      >
                        <p style={{ fontSize: 13 }}>BCH/BTC</p>
                        <p style={{ fontSize: 13 }}>0.03810000</p>
                        <p style={{ fontSize: 13 }}>0.00%</p>
                      </div>{" "}
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                          height: 36,
                        }}
                      >
                        <p style={{ fontSize: 13 }}>BCH/BTC</p>
                        <p style={{ fontSize: 13 }}>0.03810000</p>
                        <p style={{ fontSize: 13, color: "#ED1B24" }}>-6.17%</p>
                      </div>{" "}
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                          height: 36,
                        }}
                      >
                        <p style={{ fontSize: 13 }}>BCH/BTC</p>
                        <p style={{ fontSize: 13 }}>0.03810000</p>
                        <p style={{ fontSize: 13 }}>0.00%</p>
                      </div>{" "}
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                          height: 36,
                        }}
                      >
                        <p style={{ fontSize: 13 }}>BCH/BTC</p>
                        <p style={{ fontSize: 13 }}>0.03810000</p>
                        <p style={{ fontSize: 13, color: "#11D675" }}>1.92 %</p>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="MT_map"
                  >
                    <Paper
                      className="marginR2__map paddingLR"
                      style={{ height: 340 }}
                    >
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div
                            style={{ flexDirection: "column", display: "flex" }}
                          >
                            <p
                              style={{
                                fontSize: 14,
                                fontWeight: "bold",
                                marginBottom: -18,
                              }}
                            >
                              Price
                            </p>
                            <p style={{ fontSize: 12, fontWeight: "bold" }}>
                              (BTC)
                            </p>
                          </div>
                          <div
                            style={{
                              flexDirection: "column",
                              display: "flex",
                              marginLeft: 4,
                            }}
                          >
                            <img
                              style={{
                                height: 6,
                                width: 6,

                                // padding: 4,
                                marginTop: 22,
                              }}
                              src={up}
                              alt="logo"
                            />
                            <img
                              style={{
                                height: 6,
                                width: 6,
                                marginTop: 2,
                                // padding: 4,
                              }}
                              src={down}
                              alt="logo"
                            />
                          </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div
                            style={{ flexDirection: "column", display: "flex" }}
                          >
                            <p
                              style={{
                                fontSize: 14,
                                fontWeight: "bold",
                                marginBottom: -18,
                              }}
                            >
                              Amount
                            </p>
                            <p style={{ fontSize: 12, fontWeight: "bold" }}>
                              (XRP)
                            </p>
                          </div>
                          <div
                            style={{
                              flexDirection: "column",
                              display: "flex",
                              marginLeft: 4,
                            }}
                          >
                            <img
                              style={{
                                height: 6,
                                width: 6,

                                // padding: 4,
                                marginTop: 22,
                              }}
                              src={up}
                              alt="logo"
                            />
                            <img
                              style={{
                                height: 6,
                                width: 6,
                                marginTop: 2,
                                // padding: 4,
                              }}
                              src={down}
                              alt="logo"
                            />
                          </div>
                        </div>{" "}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginRight: 10,
                          }}
                        >
                          <div
                            style={{ flexDirection: "column", display: "flex" }}
                          >
                            <p
                              style={{
                                fontSize: 14,
                                fontWeight: "bold",
                                marginBottom: -18,
                              }}
                            >
                              Total
                            </p>
                            <p style={{ fontSize: 12, fontWeight: "bold" }}>
                              (BTC)
                            </p>
                          </div>
                          <div
                            style={{
                              flexDirection: "column",
                              display: "flex",
                              marginLeft: 4,
                            }}
                          >
                            <img
                              style={{
                                height: 6,
                                width: 6,

                                // padding: 4,
                                marginTop: 22,
                              }}
                              src={up}
                              alt="logo"
                            />
                            <img
                              style={{
                                height: 6,
                                width: 6,
                                marginTop: 2,
                                // padding: 4,
                              }}
                              src={down}
                              alt="logo"
                            />
                          </div>
                        </div>
                      </div>
                      <hr
                        className="borderHR"
                        style={{ width: "140%", marginLeft: -30 }}
                      />
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                          height: 36,
                        }}
                      >
                        <p style={{ fontSize: 13, color: "#ED1B24" }}>
                          1.2000000
                        </p>
                        <p style={{ fontSize: 13 }}>10.0000</p>
                        <p style={{ fontSize: 13, color: "#11D675" }}>10.000</p>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                          height: 36,
                        }}
                      >
                        <p style={{ fontSize: 13, color: "#ED1B24" }}>
                          1.2000000
                        </p>
                        <p style={{ fontSize: 13 }}>10.0000</p>
                        <p style={{ fontSize: 13 }}>10.000</p>
                      </div>{" "}
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                          height: 36,
                        }}
                      >
                        <p style={{ fontSize: 13, color: "#11D675" }}>
                          1.2000000
                        </p>
                        <p style={{ fontSize: 13 }}>10.0000</p>
                        <p style={{ fontSize: 13 }}>10.000</p>
                      </div>{" "}
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                          height: 36,
                          marginBottom: 130,
                        }}
                      >
                        <p style={{ fontSize: 13, color: "#11D675" }}>
                          1.2000000
                        </p>
                        <p style={{ fontSize: 13 }}>10.0000</p>
                        <p style={{ fontSize: 13, color: "#ED1B24" }}>10.000</p>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="MT_map"
                  >
                    <Paper
                      className="marginT2__map marginR2__map paddingLR"
                      style={{ height: 300 }}
                    >
                      <p
                        style={{
                          fontSize: 20,
                          fontWeight: "bold",
                          color: "#1E2459",
                          paddingTop: 12,
                        }}
                      >
                        TRADE HISTORY
                      </p>
                      <div style={{ marginTop: -12 }}>
                        <hr
                          className="borderHR"
                          style={{ width: "140%", marginLeft: -30 }}
                        />
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 30,
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p
                            style={{
                              fontSize: 13,
                              height: 10,
                              color: "#ED1B24",
                            }}
                          >
                            0.00928
                          </p>
                          <p
                            style={{
                              fontSize: 13,
                              height: 10,
                              color: "#11D675",
                            }}
                          >
                            0.00928
                          </p>
                          <p
                            style={{
                              fontSize: 13,
                              height: 10,
                              color: "#11D675",
                            }}
                          >
                            0.00928
                          </p>
                          <p
                            style={{
                              fontSize: 13,
                              height: 10,
                              color: "#ED1B24",
                            }}
                          >
                            0.00928
                          </p>
                          <p
                            style={{
                              fontSize: 13,
                              height: 10,
                              color: "#11D675",
                            }}
                          >
                            0.00928
                          </p>
                          <p
                            style={{
                              fontSize: 13,
                              height: 10,
                              color: "#11D675",
                            }}
                          >
                            0.00928
                          </p>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p style={{ fontSize: 13, height: 10 }}>18.8</p>
                          <p style={{ fontSize: 13, height: 10 }}>2245.7</p>
                          <p style={{ fontSize: 13, height: 10 }}>149.0</p>
                          <p style={{ fontSize: 13, height: 10 }}>53.6</p>
                          <p style={{ fontSize: 13, height: 10 }}>425.0</p>
                          <p style={{ fontSize: 13, height: 10 }}>0.1</p>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <p style={{ fontSize: 13, height: 10 }}>09:53:20</p>
                          <p style={{ fontSize: 13, height: 10 }}>09:53:20</p>
                          <p style={{ fontSize: 13, height: 10 }}>09:53:20</p>
                          <p style={{ fontSize: 13, height: 10 }}>09:53:20</p>
                          <p style={{ fontSize: 13, height: 10 }}>09:53:20</p>
                          <p style={{ fontSize: 13, height: 10 }}>09:53:20</p>
                        </div>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Grid container>
                  <Grid item xs={12} md={12} className="MT_map">
                    <Paper style={{ height: 660 }}>.</Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* Tablet View */}
      <div className="MLR__map tabletDisplay mobileDisplayNone notebookDisplayNone desktopDisplayNone">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Grid container>
              <Grid item xs={12} md={12} className="MT_map">
                <Paper className="marginR2__map paddingLR drop3iconH">
                  <div className="drop3icon">
                    <Grid item className="drop3iconWidth">
                      <FormControl className={classes.formControl}>
                        <div className="drop3iconMTML">
                          <div className="mobileDisplay tabletDisplayNone notebookDisplayNone desktopDisplayNone">
                            <Box
                              borderColor="primary.main"
                              {...defaultProps1}
                            />
                          </div>
                          <div className="mobileDisplayNone tabletDisplay notebookDisplayNone desktopDisplayNone">
                            <Box
                              borderColor="primary.main"
                              {...defaultProps2}
                            />
                          </div>
                          <div className="mobileDisplayNone tabletDisplayNone notebookDisplay desktopDisplayNone">
                            <Box
                              borderColor="primary.main"
                              {...defaultProps3}
                            />
                          </div>
                          <div className="mobileDisplayNone tabletDisplayNone notebookDisplayNone desktopDisplay">
                            <Box
                              borderColor="primary.main"
                              {...defaultProps4}
                            />
                          </div>
                        </div>
                        <div style={{ marginTop: 10, marginLeft: 0 }}>
                          <Select
                            placeholder="Select ID Types"
                            className="textWidthS"
                            displayEmpty
                            // className={classes.selectEmpty}
                            labelId="label"
                            id="select"
                          >
                            <MenuItem value="10">None</MenuItem>
                            <MenuItem value="20">BTC</MenuItem>
                          </Select>
                        </div>
                      </FormControl>
                    </Grid>{" "}
                    <Grid item className="wid1020">
                      <FormControl className={classes.formControl}>
                        <div style={{ marginTop: 10 }}>
                          <div className="mobileDisplay tabletDisplayNone notebookDisplayNone desktopDisplayNone">
                            <Box
                              borderColor="primary.main"
                              {...defaultProps1}
                            />
                          </div>
                          <div className="mobileDisplayNone tabletDisplay notebookDisplayNone desktopDisplayNone">
                            <Box
                              borderColor="primary.main"
                              {...defaultProps2}
                            />
                          </div>
                          <div className="mobileDisplayNone tabletDisplayNone notebookDisplay desktopDisplayNone">
                            <Box
                              borderColor="primary.main"
                              {...defaultProps3}
                            />
                          </div>
                          <div className="mobileDisplayNone tabletDisplayNone notebookDisplayNone desktopDisplay">
                            <Box
                              borderColor="primary.main"
                              {...defaultProps4}
                            />
                          </div>
                        </div>
                        <div style={{ marginTop: 10, marginLeft: 8 }}>
                          <Select
                            placeholder="Select ID Types"
                            className="textWidthS"
                            displayEmpty
                            // className={classes.selectEmpty}
                            labelId="label"
                            id="select"
                          >
                            <MenuItem value="10">None</MenuItem>
                            <MenuItem value="20">ETH</MenuItem>
                          </Select>
                        </div>
                      </FormControl>
                    </Grid>{" "}
                    <Grid item className="wid2010">
                      <FormControl className={classes.formControl}>
                        <div style={{ marginTop: 10 }}>
                          <div className="mobileDisplay tabletDisplayNone notebookDisplayNone desktopDisplayNone">
                            <Box
                              borderColor="primary.main"
                              {...defaultProps1}
                            />
                          </div>
                          <div className="mobileDisplayNone tabletDisplay notebookDisplayNone desktopDisplayNone">
                            <Box
                              borderColor="primary.main"
                              {...defaultProps2}
                            />
                          </div>
                          <div className="mobileDisplayNone tabletDisplayNone notebookDisplay desktopDisplayNone">
                            <Box
                              borderColor="primary.main"
                              {...defaultProps3}
                            />
                          </div>
                          <div className="mobileDisplayNone tabletDisplayNone notebookDisplayNone desktopDisplay">
                            <Box
                              borderColor="primary.main"
                              {...defaultProps4}
                            />
                          </div>
                        </div>
                        <div style={{ marginTop: 10, marginLeft: 8 }}>
                          <Select
                            placeholder="Select ID Types"
                            className="textWidthS"
                            displayEmpty
                            // className={classes.selectEmpty}
                            labelId="label"
                            id="select"
                          >
                            <MenuItem value="10">None</MenuItem>
                            <MenuItem value="20">UAD</MenuItem>
                          </Select>
                        </div>
                      </FormControl>
                    </Grid>
                    <div className="mar820">
                      <img
                        style={{
                          height: 24,
                          width: 24,

                          padding: 4,
                          marginTop: 13,
                        }}
                        src={search}
                        alt="logo"
                      />
                    </div>
                  </div>
                  <hr
                    className="borderHR"
                    style={{
                      width: "140%",
                      marginLeft: -30,
                      marginBottom: -5,
                    }}
                  />
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: -10,
                    }}
                  >
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <p style={{ fontSize: 14, fontWeight: "bold" }}>Pair</p>
                      <div style={{ marginTop: 6, marginLeft: 3 }}>
                        <img
                          style={{
                            height: 14,
                            width: 14,

                            padding: 4,
                            marginTop: 10,
                          }}
                          src={down}
                          alt="logo"
                        />
                      </div>
                    </div>
                    <p style={{ fontSize: 14, fontWeight: "bold" }}>Price</p>
                    <p style={{ fontSize: 14, fontWeight: "bold" }}>Change</p>
                  </div>
                  <hr
                    className="borderHR"
                    style={{
                      width: "140%",
                      marginLeft: -30,
                      marginTop: -5,
                    }}
                  />
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                      height: 36,
                    }}
                  >
                    <p style={{ fontSize: 13 }}>BCH/BTC</p>
                    <p style={{ fontSize: 13 }}>0.03810000</p>
                    <p style={{ fontSize: 13, color: "#11D675" }}>0.00%</p>
                  </div>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                      height: 36,
                    }}
                  >
                    <p style={{ fontSize: 13 }}>BCH/BTC</p>
                    <p style={{ fontSize: 13 }}>0.03810000</p>
                    <p style={{ fontSize: 13 }}>1.00%</p>
                  </div>{" "}
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                      height: 36,
                    }}
                  >
                    <p style={{ fontSize: 13 }}>BCH/BTC</p>
                    <p style={{ fontSize: 13 }}>0.03810000</p>
                    <p style={{ fontSize: 13 }}>0.00%</p>
                  </div>{" "}
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                      height: 36,
                    }}
                  >
                    <p style={{ fontSize: 13 }}>BCH/BTC</p>
                    <p style={{ fontSize: 13 }}>0.03810000</p>
                    <p style={{ fontSize: 13, color: "#ED1B24" }}>-6.17%</p>
                  </div>{" "}
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                      height: 36,
                    }}
                  >
                    <p style={{ fontSize: 13 }}>BCH/BTC</p>
                    <p style={{ fontSize: 13 }}>0.03810000</p>
                    <p style={{ fontSize: 13 }}>0.00%</p>
                  </div>{" "}
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                      height: 36,
                    }}
                  >
                    <p style={{ fontSize: 13 }}>BCH/BTC</p>
                    <p style={{ fontSize: 13 }}>0.03810000</p>
                    <p style={{ fontSize: 13, color: "#11D675" }}>1.92 %</p>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={12} style={{ marginTop: 20 }}>
                <Paper
                  className="marginR2__map paddingLR"
                  style={{ height: 340 }}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ flexDirection: "column", display: "flex" }}>
                        <p
                          style={{
                            fontSize: 14,
                            fontWeight: "bold",
                            marginBottom: -18,
                          }}
                        >
                          Price
                        </p>
                        <p style={{ fontSize: 12, fontWeight: "bold" }}>
                          (BTC)
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "column",
                          display: "flex",
                          marginLeft: 4,
                        }}
                      >
                        <img
                          style={{
                            height: 6,
                            width: 6,

                            // padding: 4,
                            marginTop: 22,
                          }}
                          src={up}
                          alt="logo"
                        />
                        <img
                          style={{
                            height: 6,
                            width: 6,
                            marginTop: 2,
                            // padding: 4,
                          }}
                          src={down}
                          alt="logo"
                        />
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ flexDirection: "column", display: "flex" }}>
                        <p
                          style={{
                            fontSize: 14,
                            fontWeight: "bold",
                            marginBottom: -18,
                          }}
                        >
                          Amount
                        </p>
                        <p style={{ fontSize: 12, fontWeight: "bold" }}>
                          (XRP)
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "column",
                          display: "flex",
                          marginLeft: 4,
                        }}
                      >
                        <img
                          style={{
                            height: 6,
                            width: 6,

                            // padding: 4,
                            marginTop: 22,
                          }}
                          src={up}
                          alt="logo"
                        />
                        <img
                          style={{
                            height: 6,
                            width: 6,
                            marginTop: 2,
                            // padding: 4,
                          }}
                          src={down}
                          alt="logo"
                        />
                      </div>
                    </div>{" "}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginRight: 10,
                      }}
                    >
                      <div style={{ flexDirection: "column", display: "flex" }}>
                        <p
                          style={{
                            fontSize: 14,
                            fontWeight: "bold",
                            marginBottom: -18,
                          }}
                        >
                          Total
                        </p>
                        <p style={{ fontSize: 12, fontWeight: "bold" }}>
                          (BTC)
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "column",
                          display: "flex",
                          marginLeft: 4,
                        }}
                      >
                        <img
                          style={{
                            height: 6,
                            width: 6,

                            // padding: 4,
                            marginTop: 22,
                          }}
                          src={up}
                          alt="logo"
                        />
                        <img
                          style={{
                            height: 6,
                            width: 6,
                            marginTop: 2,
                            // padding: 4,
                          }}
                          src={down}
                          alt="logo"
                        />
                      </div>
                    </div>
                  </div>
                  <hr
                    className="borderHR"
                    style={{ width: "140%", marginLeft: -30 }}
                  />
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                      height: 36,
                    }}
                  >
                    <p style={{ fontSize: 13, color: "#ED1B24" }}>1.2000000</p>
                    <p style={{ fontSize: 13 }}>10.0000</p>
                    <p style={{ fontSize: 13, color: "#11D675" }}>10.000</p>
                  </div>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                      height: 36,
                    }}
                  >
                    <p style={{ fontSize: 13, color: "#ED1B24" }}>1.2000000</p>
                    <p style={{ fontSize: 13 }}>10.0000</p>
                    <p style={{ fontSize: 13 }}>10.000</p>
                  </div>{" "}
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                      height: 36,
                    }}
                  >
                    <p style={{ fontSize: 13, color: "#11D675" }}>1.2000000</p>
                    <p style={{ fontSize: 13 }}>10.0000</p>
                    <p style={{ fontSize: 13 }}>10.000</p>
                  </div>{" "}
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                      height: 36,
                      marginBottom: 130,
                    }}
                  >
                    <p style={{ fontSize: 13, color: "#11D675" }}>1.2000000</p>
                    <p style={{ fontSize: 13 }}>10.0000</p>
                    <p style={{ fontSize: 13, color: "#ED1B24" }}>10.000</p>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={12} className="MT_map">
                <Paper
                  className="marginT2__map marginR2__map paddingLR"
                  style={{ height: 300 }}
                >
                  <p
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "#1E2459",
                      paddingTop: 12,
                    }}
                  >
                    TRADE HISTORY
                  </p>
                  <div style={{ marginTop: -12 }}>
                    <hr
                      className="borderHR"
                      style={{ width: "140%", marginLeft: -30 }}
                    />
                  </div>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: 30,
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p
                        style={{
                          fontSize: 13,
                          height: 10,
                          color: "#ED1B24",
                        }}
                      >
                        0.00928
                      </p>
                      <p
                        style={{
                          fontSize: 13,
                          height: 10,
                          color: "#11D675",
                        }}
                      >
                        0.00928
                      </p>
                      <p
                        style={{
                          fontSize: 13,
                          height: 10,
                          color: "#11D675",
                        }}
                      >
                        0.00928
                      </p>
                      <p
                        style={{
                          fontSize: 13,
                          height: 10,
                          color: "#ED1B24",
                        }}
                      >
                        0.00928
                      </p>
                      <p
                        style={{
                          fontSize: 13,
                          height: 10,
                          color: "#11D675",
                        }}
                      >
                        0.00928
                      </p>
                      <p
                        style={{
                          fontSize: 13,
                          height: 10,
                          color: "#11D675",
                        }}
                      >
                        0.00928
                      </p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p style={{ fontSize: 13, height: 10 }}>18.8</p>
                      <p style={{ fontSize: 13, height: 10 }}>2245.7</p>
                      <p style={{ fontSize: 13, height: 10 }}>149.0</p>
                      <p style={{ fontSize: 13, height: 10 }}>53.6</p>
                      <p style={{ fontSize: 13, height: 10 }}>425.0</p>
                      <p style={{ fontSize: 13, height: 10 }}>0.1</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p style={{ fontSize: 13, height: 10 }}>09:53:20</p>
                      <p style={{ fontSize: 13, height: 10 }}>09:53:20</p>
                      <p style={{ fontSize: 13, height: 10 }}>09:53:20</p>
                      <p style={{ fontSize: 13, height: 10 }}>09:53:20</p>
                      <p style={{ fontSize: 13, height: 10 }}>09:53:20</p>
                      <p style={{ fontSize: 13, height: 10 }}>09:53:20</p>
                    </div>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Grid container>
              <Grid item xs={12} md={12} className="MT_map">
                <Paper style={{ height: 1018 }}>.</Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {/* closed Tablet View */}
      <Grid container spacing={0} style={{ marginTop: 20 }}>
        <ToastContainer
          className={classes.toastsContainer}
          closeButton={
            <CloseButton className={classes.notificationCloseButton} />
          }
          closeOnClick={false}
          progressClassName={classes.notificationProgress}
        />
        <Grid item xs={12} sm={12} md={6} lg={6} className="MTtz">
          <Widget
            // title="RECENT LOGINS"
            disableWidgetMenu
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                marginTop: -48,
                marginLeft: -22,
              }}
            >
              <div className="CTradeVtradeLimit" onClick={trade}>
                <p className="CTradeV  CTradeVNotActive">MARKET</p>
              </div>
              <div className="CTradeVtradeLimit" onClick={limit}>
                <p className="market CTradeVNotActive">LIMIT</p>
              </div>
              <div className="CTradeVtradeLimit CTradeVtradeLimitEx" onClick={stopLimit}>
                <p className="stopL CTradeVNotActive">STOP LIMIT</p>
              </div>
              <div className="CTrade CTradeVActiveArea" onClick={stop}>
                <p className="stop CTradeVActive">STOP</p>
              </div>
            </div>
            <div style={{ marginTop: -8 }}>
              <hr
                className="borderHR"
                style={{ width: "140%", marginLeft: -30, color: "#F5F5F5" }}
              />
            </div>
            <div className="classMLSTSper">
              <div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ fontWeight: "bold" }}>Buy BCH </p>
                  <p style={{ fontWeight: "bold" }}>0.00000000 BTC</p>
                </div>
                <p style={{ color: "gray" }}>Market Price</p>
                <div
                  style={{
                    backgroundColor: "#F0F1F5",
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: 3,
                    paddingLeft: 10,
                    paddingRight: 10,
                    justifyContent: "space-between",
                    paddingBottom: 5,
                    borderRadius: 5,
                  }}
                >
                  <p>0.03810000 Price</p>
                  <p>BTC</p>
                </div>
                <p style={{ color: "gray" }}>Amount </p>
                <div
                  style={{
                    backgroundColor: "#F0F1F5",
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: 3,
                    paddingLeft: 10,
                    paddingRight: 10,
                    justifyContent: "space-between",
                    paddingBottom: 5,
                    borderRadius: 5,
                  }}
                >
                  <p>0 Price</p>
                  <p>BCH</p>
                </div>{" "}
                <p style={{ color: "gray" }}>Total</p>
                <div
                  style={{
                    backgroundColor: "#F0F1F5",
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: 3,
                    paddingLeft: 10,
                    paddingRight: 10,
                    justifyContent: "space-between",
                    paddingBottom: 5,
                    borderRadius: 5,
                  }}
                >
                  <p>0.000</p>
                  <p>BTC</p>
                </div>
                <div></div>
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <Button
                    size="small"
                    className="px-2"
                    variant="contained"
                    style={{
                      backgroundColor: "#11D675",
                      width: "100%",
                      color: "white",
                      height: 50,
                      fontWeight: "900",
                    }}
                  >
                    {"BUY  BCH"}
                  </Button>
                </div>
              </div>
            </div>
          </Widget>
        </Grid>{" "}
        <Grid item xs={12} sm={12} md={6} lg={6} className="MTtz">
          <Widget
            // title="RECENT LOGINS"
            disableWidgetMenu
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <p
                style={{
                  fontSize: 18,
                  fontWeight: "bolder",
                  color: "white",
                  marginTop: -20,
                }}
              >
                .
              </p>
            </div>
            <div style={{ marginTop: -9 }}>
              <hr
                className="borderHR"
                style={{ width: "140%", marginLeft: -30, color: "#F5F5F5" }}
              />
            </div>
            <div className="classMLSTSper">
              <div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ fontWeight: "bold" }}>Buy BCH </p>
                  <p style={{ fontWeight: "bold" }}>0.00000000 BTC</p>
                </div>
                <p style={{ color: "gray" }}>Market Price</p>
                <div
                  style={{
                    backgroundColor: "#F0F1F5",
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: 3,
                    paddingLeft: 10,
                    paddingRight: 10,
                    justifyContent: "space-between",
                    paddingBottom: 5,
                    borderRadius: 5,
                  }}
                >
                  <p>0.03810000 Price</p>
                  <p>BTC</p>
                </div>
                <p style={{ color: "gray" }}>Amount </p>
                <div
                  style={{
                    backgroundColor: "#F0F1F5",
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: 3,
                    paddingLeft: 10,
                    paddingRight: 10,
                    justifyContent: "space-between",
                    paddingBottom: 5,
                    borderRadius: 5,
                  }}
                >
                  <p>0 Price</p>
                  <p>BCH</p>
                </div>{" "}
                <p style={{ color: "gray" }}>Total</p>
                <div
                  style={{
                    backgroundColor: "#F0F1F5",
                    height: 40,
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: 3,
                    paddingLeft: 10,
                    paddingRight: 10,
                    justifyContent: "space-between",
                    paddingBottom: 5,
                    borderRadius: 5,
                  }}
                >
                  <p>0.000</p>
                  <p>BTC</p>
                </div>
                <div></div>
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <Button
                    size="small"
                    className="px-2"
                    variant="contained"
                    style={{
                      backgroundColor: "#ED1B24",
                      width: "100%",
                      color: "white",
                      height: 50,
                      fontWeight: "900",
                    }}
                  >
                    {"SELL  BCH"}
                  </Button>
                </div>
              </div>
            </div>
          </Widget>
        </Grid>{" "}
      </Grid>
      <Grid>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Widget>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: -10,
              }}
            >
              <p
                style={{
                  padding: 12,
                  fontSize: 18,
                  color: "#1D275A",
                  fontWeight: "bold",
                  marginTop: -16,
                }}
              >
                RECENT TRANSACTIONS
              </p>
            </div>
            <div className="RLine">
              <hr className="borderHR RLine" />
            </div>
            <div className="tableMargin MBL">
              {recentTransaction.map((recent) => (
                <Grid container spacing={3} className="MB">
                  <Grid item xs={4} sm={4} md={1} lg={1} xl={1}>
                    <div style={{ marginTop: 10 }}>
                      <img
                        style={{
                          height: 30,
                          width: 30,
                          marginRight: 5,
                          marginTop: -3,
                        }}
                        src={recent.icon1C}
                        alt="logo"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={4} md={1} lg={1} xl={1}>
                    <div style={{ marginTop: 8 }}>
                      <Button
                        size="small"
                        className="px-2"
                        variant="contained"
                        style={{
                          backgroundColor: `${recent.icon2C}`,
                          fontWeight: "bold",
                          width: 60,
                          color: "white",
                        }}
                      >
                        {recent.icon2CText}
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={4} md={2} lg={2} xl={2} className="MLF">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ marginTop: 10 }}>
                        <img
                          style={{
                            height: 30,
                            width: 30,
                            marginRight: 5,
                            marginTop: -3,
                          }}
                          src={recent.icon3S}
                          alt="logo"
                        />
                      </div>

                      <p className="tableCSSTxt">{recent.btc}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <p className="tableCSSTxt">{recent.bank}</p>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={3}
                    className="nMLF"
                  >
                    <div>
                      <p
                        className="tableCSSTxt"
                        style={{ color: `${recent.text4C}` }}
                      >
                        {recent.NValue}
                      </p>
                    </div>
                  </Grid>{" "}
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={1}
                    lg={1}
                    xl={1}
                    className="nMLF"
                  >
                    <div className="endC">
                      <p className="tableCSSTxt">{recent.PValue}</p>
                    </div>
                  </Grid>
                </Grid>
              ))}
            </div>
          </Widget>
        </Grid>
      </Grid>
      <div style={{ marginTop: 30, marginBottom: 10 }}>
        <p>Copyright 2020 BIThashEX | All Rights Reserved</p>
      </div>
    </div>
  );

  // #############################################################
  function sendNotification(componentProps, options) {
    return toast(
      <Notification
        {...componentProps}
        className={classes.notificationComponent}
      />,
      options
    );
  }

  function retryErrorNotification() {
    var componentProps = {
      type: "message",
      message: "Message was sent successfully!",
      variant: "contained",
      color: "success",
    };
    toast.update(errorToastId, {
      render: <Notification {...componentProps} />,
      type: "success",
    });
    setErrorToastId(null);
  }

  function handleNotificationCall(notificationType) {
    var componentProps;

    if (errorToastId && notificationType === "error") return;

    switch (notificationType) {
      case "info":
        componentProps = {
          type: "feedback",
          message: "New user feedback received",
          variant: "contained",
          color: "primary",
        };
        break;
      case "error":
        componentProps = {
          type: "message",
          message: "Message was not sent!",
          variant: "contained",
          color: "secondary",
          extraButton: "Resend",
          extraButtonClick: retryErrorNotification,
        };
        break;
      default:
        componentProps = {
          type: "shipped",
          message: "The item was shipped",
          variant: "contained",
          color: "success",
        };
    }

    var toastId = sendNotification(componentProps, {
      type: notificationType,
      position: positions[notificationsPosition],
      progressClassName: classes.progress,
      onClose: notificationType === "error" && (() => setErrorToastId(null)),
      className: classes.notification,
    });

    if (notificationType === "error") setErrorToastId(toastId);
  }

  function changeNotificationPosition(positionId) {
    setNotificationPosition(positionId);
  }
}

// #############################################################
function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
}
