import React from "react";

import TextField from "@material-ui/core/TextField";
import Widget from "../../components/Widget";
import { Grid, Select, MenuItem } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import useStyles from "../../../src/pages/personalverificarionform/styles";
import FormControl from "@material-ui/core/FormControl";
import "./Tables.scss";
import Box from "@material-ui/core/Box";
import { Button } from "../../components/Wrappers/Wrappers";

const datatableData = [
  ["Joe James", "Example Inc.", "Yonkers", "NY"],
  ["John Walsh", "Example Inc.", "Hartford", "CT"],
];

const defaultProps = {
  marginTop: 1.7,

  m: 1,
  borderColor: "#b8b8b8",
  border: 1,
  borderRadius: 4,
  style: { width: "74px", height: "41px" },
  position: "absolute",
};

const defaultPropsDate = {
  marginTop: 1.8,
  m: 1,
  borderColor: "#b8b8b8",
  border: 1,
  borderRadius: 4,
  style: { width: "140px", height: "41px" },
  position: "absolute",
};

const orderHistoryList = [
  {
    id: 1,
    date: "08/06/2020",
    type: "BCH",
    side: "Buy",
    price: "0.0000",
    stopPrice: "0.0000",
    filled: "0.0000",
    amount: "0.0000",
    total: "0.0000",
    status: "Pending",
    operation: "Operation",
  },
  {
    id: 2,
    date: "08/06/2020",
    type: "BCH",
    side: "Buy",
    price: "0.0000",
    stopPrice: "0.0000",
    filled: "0.0000",
    amount: "0.0000",
    total: "0.0000",
    status: "Pending",
    operation: "Operation",
  },
  {
    id: 3,
    date: "08/06/2020",
    type: "BCH",
    side: "Buy",
    price: "0.0000",
    stopPrice: "0.0000",
    filled: "0.0000",
    amount: "0.0000",
    total: "0.0000",
    status: "Pending",
    operation: "Operation",
  },
  {
    id: 4,
    date: "08/06/2020",
    type: "BCH",
    side: "Buy",
    price: "0.0000",
    stopPrice: "0.0000",
    filled: "0.0000",
    amount: "0.0000",
    total: "0.0000",
    status: "Pending",
    operation: "Operation",
  },
  {
    id: 5,
    date: "08/06/2020",
    type: "BCH",
    side: "Buy",
    price: "0.0000",
    stopPrice: "0.0000",
    filled: "0.0000",
    amount: "0.0000",
    total: "0.0000",
    status: "Pending",
    operation: "Operation",
  },
  {
    id: 6,
    date: "08/06/2020",
    type: "BCH",
    side: "Buy",
    price: "0.0000",
    stopPrice: "0.0000",
    filled: "0.0000",
    amount: "0.0000",
    total: "0.0000",
    status: "Pending",
    operation: "Operation",
  },
];

export default function Tables(props) {
  var classes = useStyles();
  function orders() {
    console.log("props --- orders", props.history);
    props.history.push("/app/tables");
  }
  function orderHistory() {
    console.log("props --- orders", props.history);
    props.history.push("/app/orderHistory");
  }
  return (
    <div className="headerContainer">
      <p
        style={{
          fontSize: 18,
          color: "#313133",
          fontWeight: "900",
          marginBottom: 20,
        }}
      >
        DASHBOARD > > ORDERS
      </p>
      <Grid>
        <Grid item xs={12}>
          <Widget>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: -50,
                marginLeft: -30,
              }}
            >
              <p
                onClick={orders}
                className="fontFamily"
                style={{
                  padding: 12,
                  fontSize: 18,
                  color: "gray",
                  paddingLeft: 20,
                }}
              >
                OPEN ORDERS
              </p>
              <p
                onClick={orderHistory}
                className="fontFamily"
                style={{
                  backgroundColor: "#1D275A",
                  padding: 12,
                  fontSize: 18,
                  color: "white",
                  marginLeft: 10,
                  borderRadius: 6,
                }}
              >
                ORDER HISTORY
              </p>
            </div>
            <div style={{ flexDirection: "row", display: "flex" }}>
              <Grid item style={{ marginRight: 5, marginTop: 0 }}>
                <InputLabel
                  style={{
                    color: "#6a6a6a",
                    fontWeight: "bold",
                    marginBottom: -4,
                  }}
                  className={classes.matlabelc}
                  shrink
                  id="label"
                >
                  START DATE
                </InputLabel>
                <div style={{ marginTop: 0, marginLeft: -10 }}>
                  <Box borderColor="primary.main" {...defaultPropsDate} />
                </div>
                <TextField
                  style={{
                    color: "#1D275A",
                    fontWeight: "bold",
                    width: 130,
                    marginTop: 12,
                    marginLeft: 6,
                  }}
                  InputProps={{
                    className: classes.inputc + " " + classes.notchedOutline,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.matlabelc,
                  }}
                  className={classes.textfieldvaluec}
                  id="establishment-date"
                  type="date"
                  value="date"
                  autoComplete="establishment-date"
                />
              </Grid>
              <Grid item style={{ marginRight: 5, marginLeft: 10 }}>
                <InputLabel
                  style={{
                    color: "#6a6a6a",
                    fontWeight: "bold",
                    marginBottom: -4,
                  }}
                  className={classes.matlabelc}
                  shrink
                  id="label"
                >
                  END DATE
                </InputLabel>
                <div style={{ marginTop: 0, marginLeft: -10 }}>
                  <Box borderColor="primary.main" {...defaultPropsDate} />
                </div>
                <TextField
                  style={{
                    color: "#1D275A",
                    fontWeight: "bold",
                    width: 130,
                    marginTop: 12,
                    marginLeft: 6,
                  }}
                  InputProps={{
                    className: classes.inputc + " " + classes.notchedOutline,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.matlabelc,
                  }}
                  className={classes.textfieldvaluec}
                  id="establishment-date"
                  type="date"
                  value="date"
                  autoComplete="establishment-date"
                />
              </Grid>
              {window.innerWidth > 500 && (
                <>
                  <Grid
                    item
                    style={{
                      width: 70,
                      marginRight: 20,
                      marginLeft: 20,
                      marginTop: -3,
                    }}
                  >
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        style={{
                          color: "#6a6a6a",
                          fontWeight: "bold",
                          marginBottom: -4,
                        }}
                        className={classes.matlabelc}
                        shrink
                        id="label"
                      >
                        PAIR
                      </InputLabel>
                      <div style={{ marginTop: 10, marginLeft: -10 }}>
                        <Box borderColor="primary.main" {...defaultProps} />
                      </div>
                      <div style={{ marginTop: 10, marginLeft: 0 }}>
                        <Select
                          placeholder="Select ID Types"
                          style={{ width: 70 }}
                          displayEmpty
                          className={classes.selectEmpty}
                          labelId="label"
                          id="select"
                        >
                          <MenuItem value="10">None</MenuItem>
                          <MenuItem value="20">ETH</MenuItem>
                        </Select>
                      </div>
                    </FormControl>
                  </Grid>{" "}
                  <Grid
                    item
                    style={{
                      width: 70,
                      marginRight: 20,
                      marginLeft: 6,
                      marginBottom: -4,
                      marginTop: -3,
                    }}
                  >
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        style={{ color: "white", fontWeight: "bold" }}
                        className={classes.matlabelc}
                        shrink
                        id="label"
                      >
                        .
                      </InputLabel>
                      <div style={{ marginTop: 10, marginLeft: -10 }}>
                        <Box borderColor="primary.main" {...defaultProps} />
                      </div>
                      <div style={{ marginTop: 10, marginLeft: 0 }}>
                        <Select
                          placeholder="Select ID Types"
                          style={{ width: 70 }}
                          displayEmpty
                          className={classes.selectEmpty}
                          labelId="label"
                          id="select"
                        >
                          <MenuItem value="10">None</MenuItem>
                          <MenuItem value="20">ETH</MenuItem>
                        </Select>
                      </div>
                    </FormControl>
                  </Grid>{" "}
                  <Grid
                    item
                    style={{
                      width: 70,
                      marginRight: 20,
                      marginLeft: 40,
                      marginTop: -3,
                    }}
                  >
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        style={{
                          color: "#6a6a6a",
                          fontWeight: "bold",
                          marginBottom: -4,
                        }}
                        className={classes.matlabelc}
                        shrink
                        id="label"
                      >
                        SIDE
                      </InputLabel>
                      <div style={{ marginTop: 10, marginLeft: -10 }}>
                        <Box borderColor="primary.main" {...defaultProps} />
                      </div>
                      <div style={{ marginTop: 10, marginLeft: 0 }}>
                        <Select
                          placeholder="Select ID Types"
                          style={{ width: 70 }}
                          displayEmpty
                          className={classes.selectEmpty}
                          labelId="label"
                          id="select"
                        >
                          <MenuItem value="10">None</MenuItem>
                          <MenuItem value="20">ETH</MenuItem>
                        </Select>
                      </div>
                    </FormControl>
                  </Grid>
                </>
              )}
            </div>
            {window.innerWidth < 400 && (
              <div
                style={{ flexDirection: "row", display: "flex", marginTop: 20 }}
              >
                <Grid item style={{ width: 70, marginRight: 20 }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      style={{ color: "#6a6a6a", fontWeight: "bolder" }}
                      className={classes.matlabelc}
                      shrink
                      id="label"
                    >
                      PAIR
                    </InputLabel>
                    <Select
                      placeholder="Select ID Types"
                      style={{ width: 70 }}
                      displayEmpty
                      className={classes.selectEmpty}
                      labelId="label"
                      id="select"
                    >
                      <MenuItem value="10">None</MenuItem>
                      <MenuItem value="20">ETH</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item style={{ width: 70, marginRight: 20 }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      style={{ color: "#6a6a6a", fontWeight: "bolder" }}
                      className={classes.matlabelc}
                      shrink
                      id="label"
                    ></InputLabel>
                    <Select
                      placeholder="Select ID Types"
                      style={{ width: 70 }}
                      displayEmpty
                      className={classes.selectEmpty}
                      labelId="label"
                      id="select"
                    >
                      <MenuItem value="10">None</MenuItem>
                      <MenuItem value="20">BTC</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item style={{ width: 70, marginRight: 20 }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      style={{ color: "#6a6a6a", fontWeight: "bolder" }}
                      className={classes.matlabelc}
                      shrink
                      id="label"
                    >
                      SIDE
                    </InputLabel>
                    <Select
                      placeholder="Select ID Types"
                      style={{ width: 70 }}
                      displayEmpty
                      className={classes.selectEmpty}
                      labelId="label"
                      id="select"
                    >
                      <MenuItem value="10">None</MenuItem>
                      <MenuItem value="20">All</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>{" "}
              </div>
            )}
            <div className="tableMargin paddingML">
              <Grid container spacing={3}>
                <Grid item xs={4} sm={3} md={2} lg={2} xl={2}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    DATE
                  </p>
                </Grid>
                <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    TYPE
                  </p>
                </Grid>
                <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    SIDE
                  </p>
                </Grid>
                <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    PRICE
                  </p>
                </Grid>
                <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    STOP PRICE
                  </p>
                </Grid>{" "}
                <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    FILLED
                  </p>
                </Grid>{" "}
                <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    AMOUNT
                  </p>
                </Grid>{" "}
                <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    TOTAL
                  </p>
                </Grid>{" "}
                <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    STATUS
                  </p>
                </Grid>{" "}
                <Grid item xs={4} sm={3} md={2} lg={2} xl={2}>
                  <div className="FDT">
                    <p
                      style={{
                        color: "#1D275A",
                        fontWeight: "bold",
                        fontSize: 9,
                      }}
                    >
                      OPERATION
                    </p>
                  </div>
                </Grid>
              </Grid>

              {orderHistoryList.map((orderHis) => (
                <Grid container spacing={3}>
                  <Grid item xs={4} sm={3} md={2} lg={2} xl={2}>
                    <p className="tableCSSTxt">{orderHis.date}</p>
                  </Grid>
                  <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                    <p className="tableCSSTxt">{orderHis.type}</p>
                  </Grid>{" "}
                  <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                    <p className="tableCSSTxt">{orderHis.side}</p>
                  </Grid>{" "}
                  <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                    <p className="tableCSSTxt">{orderHis.price}</p>
                  </Grid>{" "}
                  <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                    <p className="tableCSSTxt">{orderHis.stopPrice}</p>
                  </Grid>{" "}
                  <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                    <p className="tableCSSTxt">{orderHis.filled}</p>
                  </Grid>{" "}
                  <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                    <p className="tableCSSTxt">{orderHis.amount}</p>
                  </Grid>{" "}
                  <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                    <p className="tableCSSTxt">{orderHis.total}</p>
                  </Grid>{" "}
                  <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                    <p className="tableCSSTxt">{orderHis.status}</p>
                  </Grid>
                  <Grid item xs={4} sm={3} md={2} lg={2} xl={2}>
                    <div
                      className="MOmar"
                      style={{ flexDirection: "row-reverse", display: "flex" }}
                    >
                      <Button
                        size="small"
                        className="px-2 MR buttonCSS"
                        variant="contained"
                      >
                        {"CANCEL"}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              ))}
            </div>
          </Widget>
        </Grid>
      </Grid>
      <div style={{ marginTop: 30, marginBottom: 10 }}>
        <p>Copyright 2020 BIThashEX | All Rights Reserved</p>
      </div>
    </div>
  );
}
