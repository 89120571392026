import React from "react";

import Widget from "../../components/Widget";
import {
  Grid,
  Select,
  MenuItem,
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import useStyles from "../../../src/pages/personalverificarionform/styles";
import Box from "@material-ui/core/Box";
import "./Tables.scss";

const datatableData = [
  ["Joe James", "Example Inc.", "Yonkers", "NY"],
  ["John Walsh", "Example Inc.", "Hartford", "CT"],
];

const defaultProps = {
  marginTop: 1.7,
  m: 1,
  borderColor: "#b8b8b8",
  border: 1,
  borderRadius: 4,
  style: { width: "74px", height: "41px" },
  position: "absolute",
};

const orderHistoryList = [
  {
    id: 1,
    date: "08/06/2020",
    type: "BCH",
    side: "Buy",
    price: "0.0000",
    stopPrice: "0.0000",
    filled: "0.0000",
    amount: "0.0000",
    total: "0.0000",
    status: "Pending",
    operation: "Operation",
  },
  {
    id: 2,
    date: "08/06/2020",
    type: "BCH",
    side: "Buy",
    price: "0.0000",
    stopPrice: "0.0000",
    filled: "0.0000",
    amount: "0.0000",
    total: "0.0000",
    status: "Pending",
    operation: "Operation",
  },
  {
    id: 3,
    date: "08/06/2020",
    type: "BCH",
    side: "Buy",
    price: "0.0000",
    stopPrice: "0.0000",
    filled: "0.0000",
    amount: "0.0000",
    total: "0.0000",
    status: "Pending",
    operation: "Operation",
  },
  {
    id: 4,
    date: "08/06/2020",
    type: "BCH",
    side: "Buy",
    price: "0.0000",
    stopPrice: "0.0000",
    filled: "0.0000",
    amount: "0.0000",
    total: "0.0000",
    status: "Pending",
    operation: "Operation",
  },
  {
    id: 5,
    date: "08/06/2020",
    type: "BCH",
    side: "Buy",
    price: "0.0000",
    stopPrice: "0.0000",
    filled: "0.0000",
    amount: "0.0000",
    total: "0.0000",
    status: "Pending",
    operation: "Operation",
  },
  {
    id: 6,
    date: "08/06/2020",
    type: "BCH",
    side: "Buy",
    price: "0.0000",
    stopPrice: "0.0000",
    filled: "0.0000",
    amount: "0.0000",
    total: "0.0000",
    status: "Pending",
    operation: "Operation",
  },
];

export default function Tables(props) {
  var classes = useStyles();
  function orders() {
    console.log("props --- orders", props.history);
    props.history.push("/app/tables");
  }
  function orderHistory() {
    console.log("props --- orders", props.history);
    props.history.push("/app/orderHistory");
  }
  return (
    <div className="headerContainer">
      <p
        style={{
          fontSize: 18,
          color: "#313133",
          fontWeight: "900",
          marginBottom: 20,
        }}
      >
        DASHBOARD > > ORDERS
      </p>
      <Grid>
        <Grid item xs={12}>
          <Widget>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: -50,
                marginLeft: -30,
              }}
            >
              <p
                onClick={orders}
                className="fontFamily"
                style={{
                  backgroundColor: "#1D275A",
                  padding: 12,
                  fontSize: 18,
                  color: "white",
                  borderRadius: 10,
                  paddingLeft: 20,
                }}
              >
                OPEN ORDERS
              </p>
              <p
                onClick={orderHistory}
                className="fontFamily"
                style={{
                  padding: 12,
                  fontSize: 18,
                  color: "gray",
                  marginLeft: 10,
                }}
              >
                ORDER HISTORY
              </p>
            </div>
            <div style={{ flexDirection: "row", display: "flex" }}>
              <Grid item className="mLeft">
                <FormControl className={classes.formControl}>
                  <InputLabel
                    style={{ color: "#6a6a6a", fontWeight: "bolder" }}
                    className={classes.matlabelc}
                    shrink
                    id="label"
                  >
                    PAIR
                  </InputLabel>
                  <div style={{ marginTop: 10, marginLeft: -10 }}>
                    <Box borderColor="primary.main" {...defaultProps} />
                  </div>
                  <div style={{ marginTop: 10, marginLeft: 0 }}>
                    <Select
                      placeholder="Select ID Types"
                      style={{ width: 70 }}
                      displayEmpty
                      className={classes.selectEmpty}
                      labelId="label"
                      id="select"
                    >
                      <MenuItem value="10">None</MenuItem>
                      <MenuItem value="20">ETH</MenuItem>
                    </Select>
                  </div>
                </FormControl>
              </Grid>{" "}
              <Grid item style={{ width: 70, marginRight: 20, marginLeft: 20 }}>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    style={{ color: "white", fontWeight: "bold" }}
                    className={classes.matlabelc}
                    shrink
                    id="label"
                  >
                    .
                  </InputLabel>
                  <div style={{ marginTop: 10, marginLeft: -10 }}>
                    <Box borderColor="primary.main" {...defaultProps} />
                  </div>
                  <div style={{ marginTop: 10, marginLeft: 0 }}>
                    <Select
                      placeholder="Select ID Types"
                      style={{ width: 70 }}
                      displayEmpty
                      className={classes.selectEmpty}
                      labelId="label"
                      id="select"
                    >
                      <MenuItem value="10">None</MenuItem>
                      <MenuItem value="20">ETH</MenuItem>
                    </Select>
                  </div>
                </FormControl>
              </Grid>{" "}
              <Grid item style={{ width: 70, marginRight: 20, marginLeft: 20 }}>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    style={{ color: "#6a6a6a", fontWeight: "bolder" }}
                    className={classes.matlabelc}
                    shrink
                    id="label"
                  >
                    SIDE
                  </InputLabel>
                  <div style={{ marginTop: 10, marginLeft: -10 }}>
                    <Box borderColor="primary.main" {...defaultProps} />
                  </div>
                  <div style={{ marginTop: 10, marginLeft: 0 }}>
                    <Select
                      placeholder="Select ID Types"
                      style={{ width: 70 }}
                      displayEmpty
                      className={classes.selectEmpty}
                      labelId="label"
                      id="select"
                    >
                      <MenuItem value="10">None</MenuItem>
                      <MenuItem value="20">ETH</MenuItem>
                    </Select>
                  </div>
                </FormControl>
              </Grid>
            
            </div>

            <div className="tableMargin paddingML">
              <Grid container spacing={3}>
                <Grid item xs={4} sm={3} md={2} lg={2} xl={2}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    DATE
                  </p>
                </Grid>
                <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    TYPE
                  </p>
                </Grid>
                <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    SIDE
                  </p>
                </Grid>
                <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    PRICE
                  </p>
                </Grid>
                <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    STOP PRICE
                  </p>
                </Grid>{" "}
                <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    FILLED
                  </p>
                </Grid>{" "}
                <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    AMOUNT
                  </p>
                </Grid>{" "}
                <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    TOTAL
                  </p>
                </Grid>{" "}
                <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                  <p
                    style={{
                      color: "#1D275A",
                      fontWeight: "bold",
                      fontSize: 9,
                    }}
                  >
                    STATUS
                  </p>
                </Grid>{" "}
                <Grid item xs={4} sm={3} md={2} lg={2} xl={2}>
                  <div className="FDT">
                    <p
                      style={{
                        color: "#1D275A",
                        fontWeight: "bold",
                        fontSize: 9,
                      }}
                    >
                      OPERATION
                    </p>
                  </div>
                </Grid>
              </Grid>

              {orderHistoryList.map((orderHis) => (
                <Grid container spacing={3}>
                  <Grid item xs={4} sm={3} md={2} lg={2} xl={2}>
                    <p className="tableCSSTxt">{orderHis.date}</p>
                  </Grid>
                  <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                    <p className="tableCSSTxt">{orderHis.type}</p>
                  </Grid>{" "}
                  <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                    <p className="tableCSSTxt">{orderHis.side}</p>
                  </Grid>{" "}
                  <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                    <p className="tableCSSTxt">{orderHis.price}</p>
                  </Grid>{" "}
                  <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                    <p className="tableCSSTxt">{orderHis.stopPrice}</p>
                  </Grid>{" "}
                  <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                    <p className="tableCSSTxt">{orderHis.filled}</p>
                  </Grid>{" "}
                  <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                    <p className="tableCSSTxt">{orderHis.amount}</p>
                  </Grid>{" "}
                  <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                    <p className="tableCSSTxt">{orderHis.total}</p>
                  </Grid>{" "}
                  <Grid item xs={4} sm={3} md={1} lg={1} xl={1}>
                    <p className="tableCSSTxt">{orderHis.status}</p>
                  </Grid>
                  <Grid item xs={4} sm={3} md={2} lg={2} xl={2}></Grid>
                </Grid>
              ))}
            </div>
          </Widget>
        </Grid>
      </Grid>
      <div style={{ marginTop: 30, marginBottom: 10 }}>
        <p>Copyright 2020 BIThashEX | All Rights Reserved</p>
      </div>
    </div>
  );
}
